import { BrandingImpl } from "../BrandingConfiguration"

export interface Branding {
    /**
     * @title App & General Configuration
     * @description Configuration information for the app to be runnable
     * @title_de App & Allgemeine Konfiguration
     * @description_de Konfigurationsinformationen, damit die App überhaupt läuft
     */
    configuration: Configuration
    /**
     * @title Page Title
     * @description Title displayed in browser window for the app
     * @title_de Seitentitel
     * @description_de Titel im Browser-Fenter für die App
     */
    pageTitle: string
    /**
     * @title Available Languages
     * @description Choosable Languages
     * @title_de Verfügbare Sprachen
     * @description_de Sprachen die für die App zur Verfügung stehen
     */
    availableLanguages: string[]
    /**
     * @title Loader bg color
     * @description Background color for the centered loader
     * @title_de Loader bg color
     * @description_de Background color for the centered loader
     */
    loaderBgColor: string
    /**
     * @title Loader spin color
     * @description Top border color for the centered loader
     * @title_de Loader spin color
     * @description_de Top border color for the centered loader
     */
    loaderSpinColor: ColorString
}

export interface Configuration {
    /**
     * @title topic name
     * @description Name of the topic for the data access (e.g. 2021_hh)
     * @title_de topic name
     * @description_de Name des topics für den Datenzugriff (z.b. 2021_hh)
     */
    topicName: string
    /**
     * @title series of topics name
     * @description Name of the series of topics for the data access
     * @title_de series of topics name
     * @description_de Name des series of topics für den Datenzugriff
     */
    sotName: string
    /**
     * @title series of topics token
     * @description series of topics token for the data access
     * @title_de series of topics token
     * @description_de series of topics token für den Datenzugriff
     */
    sotAccessToken: string
    /**
     * @title Open Graph Title
     * @description Title for the meta-tag og:title
     * @title_de Open Graph Titel
     * @description_de Titel für das og:title meta-tag
     */
    ogTitle: LocalizedString
    /**
     * @title Open Graph Description
     * @description Title for the meta-tag og:title
     * @title_de Open Graph Beschreibung
     * @description_de Titel für das og:description meta-tag
     */
    ogDescription: LocalizedString
    /**
     * @title Dominant color
     * @description Dominant color in the application
     * @title_de Dominant color
     * @description_de Dominant color in the application
     */
    maincolor: ColorString
    /**
     * @title Date format
     * @description Picker's date format
     * @title_de Date format
     * @description_de Picker's date format
     */
    dateFormat: string
    /**
     * @title Time format
     * @description Picker's time format
     * @title_de Time format
     * @description_de Picker's time format
     */
    timeFormat: string
    /**
     * @title Categories visibility flag
     * @description Flag to enable the categories' code visibility
     * @title_de Categories visibility flag
     * @description_de Flag to enable the categories' code visibility
     */
    showCategoriesCode: boolean
    /**
     * @title Licences visibility flag
     * @description Flag to enable the licences' code visibility
     * @title_de Lizenzen Sichtbarkeitskennzeichen
     * @description_de Flagge zur Aktivierung der Sichtbarkeit des Lizenzcodes
     */
    showLicencesCode: boolean
    /**
     * @title Searchbar hint
     * @description Hint for the searchbar input field
     * @title_de Searchbar hint
     * @description_de Hint for the searchbar input field
     */
    hntSearch: LocalizedString
    /**
     * @title Chips hint
     * @description Hint for the chips input field
     * @title_de Chips hint
     * @description_de Hint for the chips input field
     */
    hntChips: LocalizedString
    /**
     * @title Comming soon label
     * @description Label for the Comming soon message
     * @title_de Comming soon label
     * @description_de Label for the Comming soon message
     */
    lblCommingSoon: LocalizedString
    /**
     * @title Empty dropdown value label
     * @description Label for the empty dropdown value
     * @title_de Empty dropdown value label
     * @description_de Label for the empty dropdown value
     */
    lblEmptySelect: LocalizedString
    /**
     * @title Page counter
     * @description Label for Page Side Title where counts are shown
     * @title_de Page counter
     * @description_de Label for Page Side Title where counts are shown
     */
    pageCounter: LocalizedString
    /**
     * @title Page counter
     * @description Label for Page Side Title where counts are shown
     * @title_de Page counter
     * @description_de Label for Page Side Title where counts are shown
     */
    pageCounterMiddle: LocalizedString
    /**
     * @title ModalPublish labels
     * @description Labels for the ModalPublish
     * @title_de ModalPublish labels
     * @description_de Labels for the ModalPublish
     */
    modalPublish: ModalPublishConfiguration
    /**
     * @title Language switch labels
     * @description Labels for the Language switch
     * @title_de Language switch labels
     * @description_de Labels for the Language switch
     */
    languageSwitch: LanguageSwitchConfiguration
    /**
     * @title Actionbar labels
     * @description Labels for the actionbar
     * @title_de Actionbar labels
     * @description_de Labels for the actionbar
     */
    actionbar: ActionbarConfiguration
    /**
     * @title Sidebar labels
     * @description Labels for the sidebar menu
     * @title_de Beschriftungen der Seitenleiste
     * @description_de Beschriftungen für das Seitenleistenmenü
     */
    sidebar: SidebarConfiguration
    /**
     * @title Profile page config
     * @description Labels for the Profile page
     * @title_de Konfiguration der Profilseite
     * @description_de Beschriftungen für die Profilseite
     */
    profilePage: ProfilePageConfiguration
    /**
     * @title Links page config
     * @description Labels for the Links page
     * @title_de Links Seite konfigurieren
     * @description_de Beschriftungen für die Seite "Links"
     */
    linksPage: LinksPageConfiguration
    /**
     * @title Categories page config
     * @description Labels for the Categories page
     * @title_de Konfiguration der Kategorieseite
     * @description_de Beschriftungen für die Seite "Kategorien"
     */
    categoriesPage: CategoriesPageConfiguration
    /**
     * @title Licences page config
     * @description Labels for the Licences page
     * @title_de Konfiguration der Lizenzen
     * @description_de Beschriftungen für die Seite "Lizenzen"
     */
    licencesPage: LicencesPageConfiguration
    /**
     * @title Products page config
     * @description Labels for the Products page
     * @title_de Konfiguration der Produktseite
     * @description_de Etiketten für die Seite "Produkte"
     */
    productsPage: ProductsPageConfiguration
    /**
     * @title Trademarks page config
     * @description Labels for the Trademarks page
     * @title_de Konfiguration der Markenseite
     * @description_de  Etiketten für die Seite "Marken"
     */
    trademarksPage: TrademarksPageConfiguration
    /**
     * @title Team page config
     * @description Labels for the Team page
     * @title_de Konfiguration der Teamseite
     * @description_de Beschriftungen für die Seite "Team"
     */
    teamPage: TeamPageConfiguration
    /**
     * @title Multimedia page config
     * @description Labels for the Multimedia page
     * @title_de Konfiguration der Multimedia-Seite
     * @description_de Beschriftungen für die Multimedia-Seite
     */
    multimediaPage: MultimediaPageConfiguration
    /**
     * @title News page config
     * @description Labels for the News page
     * @title_de Konfiguration der Nachrichtenseite
     * @description_de Labels für die News-Seite
     */
    newsPage: NewsPageConfiguration
    /**
     * @title News page config
     * @description Labels for the News page
     * @title_de Konfiguration der Nachrichtenseite
     * @description_de Labels für die News-Seite
     */
    eventDatesPage: EventDatesPageConfiguration
    /**
     * @title Showrooms page config
     * @description Labels for the Showrooms page
     * @title_de --
     * @description_de --
     */
    showroomsPage: ShowroomsPageConfiguration
    /**
     * @title Coupons page config
     * @description Labels for the Coupons page
     * @title_de --
     * @description_de --
     */
    couponsPage: CouponsPageConfiguration
    /**
     * @title Jobs page config
     * @description Labels for the Jobs page
     * @title_de --
     * @description_de --
     */
    jobsPage: JobsPageConfiguration
    /**
     * @title Print page config
     * @description Labels for the Print page
     * @title_de --
     * @description_de --
     */
    printPage: PrintPageConfiguration
    /**
     * @title Event Shop page config
     * @description Configuration for the Event Shop page
     * @title_de --
     * @description_de --
     */
    eventShopPage: EventShopPageConfiguration
    /**
     * @title Validation messages page config
     * @description Configuration for the validation messages
     * @title_de --
     * @description_de --
     */
    validationMessages: ValidationMessagesConfiguration
    /**
     * @title Error messages page config
     * @description Configuration for the validation messages
     * @title_de --
     * @description_de --
     */
    errorMessages: ErrorMessagesConfiguration
    /**
     * @title Mono- or bi-lingual data entry
     * @description Monolingual or bilingual data entry
     * @title_de Ein- oder zweisprachige Dateneingabe
     * @description_de Einsprachige oder zweisprachige Dateneingabe
     */
    monoLingual: boolean
    /**
     * @title Categories countable
     * @description Categories allow to be counted differently
     * @title_de Kategorien zählbar
     * @description_de Kategorien können unterschiedlich gezählt werden
     */
    countableCategories: CategoriesCountableConfiguration
}

export interface CategoriesCountableConfiguration {
    /**
     * @title Categories countable
     * @description Categories allow to be counted differently
     * @title_de Kategorien zählbar
     * @description_de Kategorien können unterschiedlich gezählt werden
     */
    categoriesCountableCategories: string[]
    /**
     * @title Categories countable in Job page
     * @description Categories allow to be counted in Job page
     * @title_de Kategorien zählbar in Job-Seite
     * @description_de Kategorien können auf der Job-Seite gezählt werden
     */
    jobCountableCategories: string[]
    /**
     * @title Categories countable in News page
     * @description Categories allow to be counted in News page
     * @title_de Kategorien zählbar in News-Seite
     * @description_de Kategorien können auf der News-Seite gezählt werden
     */
    newsCountableCategories: string[]
    /**
     * @title Categories countable in Product page
     * @description Categories allow to be counted in Product page
     * @title_de Kategorien zählbar in Product-Seite
     * @description_de Kategorien können auf der Product-Seite gezählt werden
     */
    productCountableCategories: string[]
    /**
     * @title Categories countable in Trademark page
     * @description Categories allow to be counted in Trademark page
     * @title_de Kategorien zählbar in Trademark-Seite
     * @description_de Kategorien können auf der Trademark-Seite gezählt werden
     */
    trademarkCountableCategories: string[]
    /**
     * @title Limit exceeded warning message
     * @description Message to be displayed when the category selection limit has been exceeded
     * @title_de --
     * @description_de --
     */
    warningMessage: LocalizedString
}

export interface ValidationMessagesConfiguration {
    /**
     * @title Company name missing error
     * @description Error message for missing company name
     * @title_de --
     * @description_de --
     */
    missingCompanyName: LocalizedString
    /**
     * @title Company address missing error
     * @description Error message for missing company address
     * @title_de --
     * @description_de --
     */
    missingCompanyAddress: LocalizedString
    /**
     * @title Company postcode missing error
     * @description Error message for missing company postcode
     * @title_de --
     * @description_de --
     */
    missingCompanyPostcode: LocalizedString
    /**
     * @title Company city missing error
     * @description Error message for missing company city
     * @title_de --
     * @description_de --
     */
    missingCompanyCity: LocalizedString
    /**
     * @title Company name missing error
     * @description Error message for missing company name
     * @title_de --
     * @description_de --
     */
    missingCompanyCountry: LocalizedString
    /**
     * @title Email invalid error
     * @description Error message for invalid email name
     * @title_de --
     * @description_de --
     */
    invalidCompanyEmail: LocalizedString
    /**
     * @title Email invalid error
     * @description Error message for invalid email name
     * @title_de --
     * @description_de --
     */
    invalidCompanyEmail2: LocalizedString
    /**
     * @title Phone invalid error
     * @description Error message for invalid phone number
     * @title_de --
     * @description_de --
     */
    missingCompanyPhone: LocalizedString
    /**
     * @title Mobile invalid error
     * @description Error message for invalid mobile number
     * @title_de --
     * @description_de --
     */
    missingCompanyMobile: LocalizedString
    /**
     * @title Fax invalid error
     * @description Error message for invalid fax name
     * @title_de --
     * @description_de --
     */
    missingCompanyFax: LocalizedString
    /**
     * @title Email invalid error
     * @description Error message for invalid email name
     * @title_de --
     * @description_de --
     */
    missingCompanyEmail: LocalizedString
    /**
     * @title Website invalid error
     * @description Error message for invalid website name
     * @title_de --
     * @description_de --
     */
    missingCompanyWebsite: LocalizedString
    /**
     * @title Size invalid error
     * @description Error message for invalid size
     * @title_de --
     * @description_de --
     */
    missingCompanySize: LocalizedString
    /**
     * @title Industry invalid error
     * @description Error message for invalid industry
     * @title_de --
     * @description_de --
     */
    missingCompanyIndustry: LocalizedString
    /**
     * @title FoundingYear invalid error
     * @description Error message for invalid FoundingYear
     * @title_de --
     * @description_de --
     */
    missingCompanyFoundingYear: LocalizedString
    /**
     * @title Link name missing error
     * @description Error message for missing link name
     * @title_de --
     * @description_de --
     */
    missingLinkName: LocalizedString
    /**
     * @title Link url missing error
     * @description Error message for missing link url
     * @title_de --
     * @description_de --
     */
    missingLinkUrl: LocalizedString
    /**
     * @title Product name missing error
     * @description Error message for missing product name
     * @title_de --
     * @description_de --
     */
    missingProductName: LocalizedString
    /**
     * @title Product start date is greater than or equal to current date error
     * @description Error message for start date greater or equal than current date
     * @title_de --
     * @description_de --
     */
    dateGreaterOrEqualThanCurrentDate: LocalizedString
    /**
     * @title Product end date error greater than current date error
     * @description Error message for end date grater than current date
     * @title_de --
     * @description_de --
     */
    dateGreaterThanCurrentDate: LocalizedString
    /**
     * @title Product publication end date missing error
     * @description Error message for missing product publication end date
     * @title_de --
     * @description_de --
     */
    invalidProductPublicationEndDate: LocalizedString
    /**
     * @title Trademark name missing error
     * @description Error message for missing trademark name
     * @title_de --
     * @description_de --
     */
    missingTrademarkName: LocalizedString
    /**
     * @title first name missing error
     * @description Error message for missing first name
     * @title_de --
     * @description_de --
     */
    missingFirstName: LocalizedString
    /**
     * @title Last name name missing error
     * @description Error message for missing last name
     * @title_de --
     * @description_de --
     */
    missingLastName: LocalizedString
    /**
     * @title Email missing error
     * @description Error message for missing email
     * @title_de --
     * @description_de --
     */
    missingEmail: LocalizedString
    /**
     * @title Email invalid error
     * @description Error message for invalid email name
     * @title_de --
     * @description_de --
     */
    invalidEmail: LocalizedString
    /**
     * @title Role missing error
     * @description Error message for missing role
     * @title_de --
     * @description_de --
     */
    missingRole: LocalizedString
    /**
     * @title Media title missing error
     * @description Error message for missing media title
     * @title_de --
     * @description_de --
     */
    missingMediaTitle: LocalizedString
    /**
     * @title News title missing error
     * @description Error message for missing News title
     * @title_de --
     * @description_de --
     */
    missingNewsTitle: LocalizedString
    /**
     * @title News date missing error
     * @description Error message for missing News date
     * @title_de --
     * @description_de --
     */
    missingNewsDate: LocalizedString
    /**
     * @title Event date title missing error
     * @description Error message for missing event date title
     * @title_de --
     * @description_de --
     */
    missingEventDateTitle: LocalizedString
    /**
     * @title Showroom title missing error
     * @description Error message for missing showroom title
     * @title_de --
     * @description_de --
     */
    missingShowroomTitle: LocalizedString
    /**
     * @title Coupon title missing error
     * @description Error message for missing coupon title
     * @title_de --
     * @description_de --
     */
    missingCouponName: LocalizedString
    /**
     * @title Coupon Url missing error
     * @description Error message for missing coupon url
     * @title_de --
     * @description_de --
     */
    missingCouponValidUrl: LocalizedString
    /**
     * @title Job title missing error
     * @description Error message for missing job title
     * @title_de --
     * @description_de --
     */
    missingJobTitle: LocalizedString
    /**
     * @title Job start date missing error
     * @description Error message for missing start date
     * @title_de --
     * @description_de --
     */
    missingJobValidStartDate: LocalizedString
    /**
     * @title Job start displaying date missing error
     * @description Error message for missing start displaying date
     * @title_de --
     * @description_de --
     */
    missingJobValidStartDisplaying: LocalizedString
    /**
     * @title Company name missing error
     * @description Error message for missing company name
     * @title_de --
     * @description_de --
     */
    missingPrintCompanyName: LocalizedString
    /**
     * @title Address missing error
     * @description Error message for missing address
     * @title_de --
     * @description_de --
     */
    missingPrintAddress: LocalizedString
    /**
     * @title Zip code missing error
     * @description Error message for missing zip code
     * @title_de --
     * @description_de --
     */
    missingPrintZipcode: LocalizedString
    /**
     * @title City missing error
     * @description Error message for missing city
     * @title_de --
     * @description_de --
     */
    missingPrintCity: LocalizedString
    /**
     * @title Country missing error
     * @description Error message for missing country
     * @title_de --
     * @description_de --
     */
    missingPrintCountry: LocalizedString
    /**
     * @title Coupon's valid start date missing error
     * @description Error message for missing Coupon's valid start date
     * @title_de --
     * @description_de --
     */
    missingValidStartDate: LocalizedString
    /**
     * @title Coupon's valid end date missing error
     * @description Error message for missing Coupon's valid end date
     * @title_de --
     * @description_de --
     */
    missingValidEndDate: LocalizedString
    /**
     * @title Success message title
     * @description Title of success message popup
     * @title_de --
     * @description_de --
     */
    successMessageTitle: LocalizedString
    /**
     * @title Success message text
     * @description Text of success message popup
     * @title_de --
     * @description_de --
     */
    successMessageText: LocalizedString
    /**
     * @title Warning message title
     * @description Title of warning message popup
     * @title_de --
     * @description_de --
     */
    warningMessageTitle: LocalizedString
    /**
     * @title Error message title
     * @description Title of error message popup
     * @title_de --
     * @description_de --
     */
    errorMessageTitle: LocalizedString
}

export interface ErrorMessagesConfiguration {
    /**
     * @title Invalid file type error
     * @description Error message for invalid file type
     * @title_de --
     * @description_de --
     */
    invalidFileType: LocalizedString
}
export interface ModalPublishConfiguration {
    /**
     * @title Modal's title
     * @description Title for the publish modal
     * @title_de Modal's title
     * @description_de Title for the publish modal
     */
    title: LocalizedString
    /**
     * @title Publish content text
     * @description Content text for the publish modal
     * @title_de Publish content text
     * @description_de Content text for the publish modal
     */
    label: LocalizedString
    /**
     * @title Failed validation field names mappings
     * @description Mapping between failed validation field names and the labels displayed on the publish modal
     * @title_de --
     * @description_de --
     */
    fieldNamesConfig: ValidationFieldNamesConfiguration

    /**
     * @title Error message visibility duration
     * @description Duration of visibility of error message in millisecionds
     * @title_de --
     * @description_de --
     */
    visibilityDuration: number
}

export interface LanguageSwitchConfiguration {
    /**
     * @title English option label
     * @description Label for the English option in the language switch
     * @title_de English option label
     * @description_de Label for the English option in the language switch
     */
    lblEnglishOption: LocalizedString
    /**
     * @title German option label
     * @description Label for the German option in the language switch
     * @title_de German option label
     * @description_de Label for the German option in the language switch
     */
    lblGermanOption: LocalizedString
}

export interface ActionbarConfiguration {
    /**
     * @title Publish button
     * @description Label for the Publish button
     * @title_de Publish button
     * @description_de Label for the Publish button
     */
    btnPublish: LocalizedString
    /**
     * @title Discard button
     * @description Label for the Discard button
     * @title_de Discard button
     * @description_de Label for the Discard button
     */
    btnDiscard: LocalizedString
    /**
     * @title Discard modal title
     * @description Title of the discard modal
     * @title_de Discard modal title
     * @description_de Title of the discard modal
     */
    modalDiscardTitle: LocalizedString
    /**
     * @title Discard modal text
     * @description Text the discard modal
     * @title_de Discard modal text
     * @description_de Text the discard modal
     */
    modalDiscardText: LocalizedString
    /**
     * @title Discard modal OK button text
     * @description Label for the OK button in discard modal
     * @title_de Discard modal OK button text
     * @description_de Label for the OK button in discard modal
     */
    modalDiscardOK: LocalizedString
    /**
     * @title Discard modal cancel button text
     * @description Label for the cancel button in discard modal
     * @title_de Discard modal cancel button text
     * @description_de Label for the cancel button in discard modal
     */
    modalDiscardCancel: LocalizedString
}

export enum SidebarSection {
    PROFILE = "PROFILE",
    CATEGORY = "CATEGORY",
    LICENCES = "LICENCES",
    LINKS = "LINKS",
    PRODUCTS = "PRODUCTS",
    TRADEMARKS = "TRADEMARKS",
    TEAM = "TEAM",
    MULTIMEDIA = "MULTIMEDIA",
    NEWS = "NEWS",
    EVENTDATES = "EVENTDATES",
    SHOWROOMS = "SHOWROOMS",
    COUPONS = "COUPONS",
    JOBS = "JOBS",
    PRINT = "PRINT",
    SHOP = "SHOP"
}

export interface SidebarConfiguration {
    /**
     * @title Items order
     * @description Order of items in the sidebar navigation, item is not shown in the profile if not listed here
     * @title_de Ordnung der Elemente
     * @description_de Ordnung der Elemente in der Sidebar-Navigation, Element wird nicht im Profil angezeigt, wenn es hier nicht aufgeführt ist
     */
    sectionsOrder: SidebarSection[]
    /**
     * @title Profile label
     * @description Label for the Profile menu entry
     * @title_de Profil label
     * @description_de Bezeichnung für den Menüeintrag Profil
     */
    lblProfile: LocalizedString
    /**
     * @title Categories label
     * @description Label for the Categories menu entry
     * @title_de Categories label
     * @description_de  Beschriftung für den Menüeintrag Kategorien
     */
    lblCategories: LocalizedString
    /**
     * @title Licences label
     * @description Label for the Licences menu entry
     * @title_de Lizenzen label
     * @description_de Beschriftung für den Menüeintrag "Lizenzen"
     */
    lblLicences: LocalizedString
    /**
     * @title Licences URL
     * @description URL for the Licences menu entry
     * @title_de Lizenzen URL
     * @description_de URL für den Menüeintrag "Lizenzen"
     */
    urlLicences: string
    /**
     * @title Links label
     * @description Label for the Links menu entry
     * @title_de Links label
     * @description_de Beschriftung für den Menüeintrag "Links"
     */
    lblLinks: LocalizedString
    /**
     * @title Products label
     * @description Label for the Products menu entry
     * @title_de Produktetikett
     * @description_de  Bezeichnung für den Menüeintrag Produkte
     */
    lblProducts: LocalizedString
    /**
     * @title Trademarks label
     * @description Label for the Trademarks menu entry
     * @title_de Handelsmarken Label
     * @description_de Bezeichnung für den Menüeintrag Marken
     */
    lblTrademarks: LocalizedString
    /**
     * @title Team label
     * @description Label for the Team menu entry
     * @title_de Mannschaft label
     * @description_de Bezeichnung für den Menüeintrag Team
     */
    lblTeam: LocalizedString
    /**
     * @title Multimedia label
     * @description Label for the Multimedia menu entry
     * @title_de Multimedia label
     * @description_de Beschriftung für den Menüeintrag Multimedia
     */
    lblMultimedia: LocalizedString
    /**
     * @title News label
     * @description Label for the News menu entry
     * @title_de Nachrichtenlabel
     * @description_de  Bezeichnung für den Menüeintrag Nachrichten
     */
    lblNews: LocalizedString
    /**
     * @title Event dates label
     * @description Label for the Event dates menu entry
     * @title_de Label für Veranstaltungsdaten
     * @description_de Bezeichnung für den Menüeintrag Veranstaltungsdaten
     */
    lblEventDate: LocalizedString
    /**
     * @title Showrooms label
     * @description Label for the Showrooms menu entry
     * @title_de --
     * @description_de --
     */
    lblShowrooms: LocalizedString
    /**
     * @title Coupons label
     * @description Label for the Coupons menu entry
     * @title_de --
     * @description_de --
     */
    lblCoupons: LocalizedString
    /**
     * @title Jobs label
     * @description Label for the Jobs menu entry
     * @title_de --
     * @description_de --
     */
    lblJobs: LocalizedString
    /**
     * @title Print label
     * @description Label for the Print menu entry
     * @title_de --
     * @description_de --
     */
    lblPrint: LocalizedString
    /**
     * @title Event Shop label
     * @description Label for the Event Shop menu entry
     * @title_de --
     * @description_de --
     */
    lblEventShop: LocalizedString
}

export enum ProfileSection {
    LOGO = "LOGO",
    MOBILE_HEADER_IMAGE = "MOBILE_HEADER_IMAGE",
    VIDEO = "VIDEO",
    BASIC_INFO = "BASIC_INFO",
    COMPANY_SORT = "COMPANY_SORT",
    DESCRIPTION = "DESCRIPTION",
    KEYWORDS = "KEYWORDS",
    SOCIAL_MEDIA = "SOCIAL_MEDIA",
    IFRAME = "IFRAME",
    CONSENT_CONTENT = "CONSENT_CONTENT"
}

export interface ProfilePageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Sections order
     * @description Order of sections in the profile page, section is not shown in the profile if not listed here
     * @title_de Sektionsordnung
     * @description_de Ordnung der Sektionen auf der Profilseite, Sektion wird nicht im Profil angezeigt, wenn sie hier nicht aufgeführt ist
     */
    sectionsOrder: ProfileSection[]
    /**
     * @title Upload Section config
     * @description Label for the Upload profile page entry
     * @title_de Hochladen label
     * @description_de Bezeichnung für den Eintrag der Profilseite hochladen
     */
    sctUpload: UploadSectionConfiguration
    /**
     * @title Upload Mobile Header Image Section config
     * @description Label for the Upload Mobile Header Image profile page entry
     * @title_de Upload Mobile Header Image Section config
     * @description_de Bezeichnung für den Eintrag auf der Profilseite Mobiles Kopfbild hochladen
     */
    sctMobileHeaderImageUpload: UploadMobileHeaderImageSectionConfiguration
    /**
     * @title Video Section config
     * @description Label for the Video profile page entry
     * @title_de Konfiguration des Videoabschnitts
     * @description_de Beschriftung für den Eintrag auf der Videoprofilseite
     */
    sctVideo: VideoSectionConfiguration
    /**
     * @title Basic Info Section config
     * @description Label for the Basic Info Section profile page entry
     * @title_de Konfiguration des Abschnitts "Basic Info"
     * @description_de Bezeichnung für den Profilseiteneintrag des Abschnitts "Grundlegende Informationen"
     */
    sctBasicInfo: BasicInfoSectionConfiguration
    /**
     * @title Basic Info Section readonly config
     * @description Label for the Basic Info Section readonly profile page entry
     * @title_de Konfiguration des schreibgeschützten Bereichs "Basisinformationen
     * @description_de Etikett für den Eintrag auf der Profilseite des Abschnitts Basisinfos, der nur gelesen werden kann"
     */
    sctBasicInfoReadonly: BasicInfoSectionConfigurationReadonly
    /**
     * @title Basic Info Section mandatory config
     * @description Label for the Basic Info Section mandatory profile page entry
     * @title_de Grundlegende Informationen Abschnitt obligatorische Konfiguration
     * @description_de Etikett für den obligatorischen Eintrag auf der Profilseite im Abschnitt Basisinformationen
     */
    sctBasicInfoMandatory: BasicInfoSectionConfigurationMandatory
    /**
     * @title Description Section config
     * @description Label for the Description Section page profile entry
     * @title_de Beschreibung Abschnitt konfiguration
     * @description_de Beschriftung für den Profileintrag der Seite "Beschreibungsabschnitt"
     */
    sctDescription: DescriptionSectionConfiguration
    /**
     * @title Keywords Section config
     * @description Label for the Keywords Section page profile entry
     * @title_de Konfiguration des Abschnitts "Schlüsselwörter"
     * @description_de Beschriftung für den Profileintrag der Seite "Keywords Section"
     */
    sctKeywords: KeywordsSectionConfiguration
    /**
     * @title Social Media Section config
     * @description Label for the Social Media Section page profile entry
     * @title_de Social Media Section Konfiguration
     * @description_de Label für den Profileintrag der Social Media Section-Seite
     */
    sctSocialMedia: SocialMediaSectionConfiguration
    /**
     * @title Frame Section config
     * @description Label for the Frame Section page profile entry
     * @title_de Konfiguration des Rahmenabschnitts
     * @description_de Beschriftung für den Profileintrag der Seite "Frame Section"
     */
    sctiFrame: iFrameSectionConfiguration
    /**
     * @title Consent Content Section config
     * @description Label for the Consent Content Section page profile entry
     * @title_de Konfiguration des Zustimmungsinhaltsbereichs
     * @description_de Bezeichnung für den Profileintrag der Seite "Zustimmungsinhalt"
     */
    sctConsentContent: ConsentContentSectionConfiguration
}

export interface UploadSectionConfiguration {
    /**
     * @title Upload label
     * @description Label for the Upload input
     * @title_de Label hochladen
     * @description_de Label für den Upload-Eingang
     */
    lblUpload: LocalizedString
    /**
     * @title Company Logo placeholder
     * @description Label for the Company Logo input
     * @title_de Platzhalter für das Firmenlogo
     * @description_de Etikett für die Eingabe des Firmenlogos
     */
    lblCompanyLogo: LocalizedString
    /**
     * @title Company Background placeholder
     * @description Label for the Company Background input
     * @title_de Platzhalter "Unternehmenshintergrund"
     * @description_de Beschriftung für die Eingabe "Unternehmenshintergrund"
     */
    lblCompanyBackground: LocalizedString
    /**
     * @title Company Image Title label
     * @description Label for the Company Image Title input
     * @title_de Titelbezeichnung des Firmenbilds
     * @description_de Beschriftung für die Eingabe des Unternehmensbildtitels
     */
    hntTitle: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Label for the Company Image Properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntProp: LocalizedString
    /**
     * @title Company Background Title label
     * @description Label for the Company Background Title input
     * @title_de Titelbezeichnung des Firmenhintergrundes
     * @description_de Beschriftung für die Eingabe des Unternehmenshintergrundtitels
     */
    hntTitleBackground: LocalizedString
    /**
     * @title Company Background Properties placeholder
     * @description Label for the Company Background Properties input
     * @title_de Eigenschaften des Unternehmenshintergrund
     * @description_de Bezeichnung für die Eingabe "Unternehmenshintergrundeigenschaften"
     */
    hntPropBackground: LocalizedString
    /**
     * @title Logo Browse files Button
     * @description Label for the Button Browse files
     * @title_de Logo Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFilesLogo: LocalizedString
    /**
     * @title Logo Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Logo Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImgLogo: LocalizedString
    /**
     * @title Logo Browse files Button
     * @description Label for the Button Browse files
     * @title_de Logo Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFilesBackground: LocalizedString
    /**
     * @title Logo Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Logo Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImgBackground: LocalizedString
}

export interface UploadMobileHeaderImageSectionConfiguration {
    /**
     * @title Upload mobile header label image
     * @description Label for the Upload mobile header image
     * @title_de Bild der mobilen Kopfzeile hochladen
     * @description_de Etikett für die Kopfzeile von Upload mobile
     */
    lblUpload: LocalizedString
    /**
     * @title Header image placeholder
     * @description Label for the Header image input
     * @title_de Platzhalter für Kopfzeilenbild
     * @description_de Beschriftung für die Eingabe des Kopfzeilenbildes
     */
    lblHeaderImage: LocalizedString
    /**
     * @title Header Image Title label
     * @description Label for the Header Image Title input
     * @title_de Kopfzeilenbild Titelbezeichnung
     * @description_de Beschriftung für die Eingabe Header Image Title
     */
    hntTitleHeaderImage: LocalizedString
    /**
     * @title Header Image Properties placeholder
     * @description Label for the Header Image Properties input
     * @title_de Kopfzeilenbild-Eigenschaften Platzhalter
     * @description_de  Beschriftung für die Eingabe der Kopfzeileneigenschaften
     */
    hntPropHeaderImage: LocalizedString
    /**
     * @title Logo Browse files Button
     * @description Label for the Button Browse files
     * @title_de Logo Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFilesHeaderInage: LocalizedString
    /**
     * @title Logo Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Logo Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImgHeader: LocalizedString
}

export interface VideoSectionConfiguration {
    /**
     * @title Company video label
     * @description Label for the Company video input
     * @title_de Videolabel des Unternehmens
     * @description_de Label für den Firmenvideoeingang
     */
    lblCompanyVideo: LocalizedString
    /**
     * @title Company thumbnail label
     * @description Label for the Company thumbnail input
     * @title_de Beschriftung des Miniaturansichtsabschnitts
     * @description_de Beschriftung für die Eingabe des Miniaturansichtsabschnitts
     */
    lblThumbnail: LocalizedString
    /**
     * @title Company video placeholder
     * @description Label for the Company video input
     * @title_de Platzhalter "Unternehmenshintergrund"
     * @description_de Beschriftung für die Eingabe "Unternehmenshintergrund"
     */
    lblVideo: LocalizedString
    /**
     * @title Company thumbnail title label
     * @description Label for the Company thumbnail title input
     * @title_de Titelbezeichnung des Firmenbilds
     * @description_de Beschriftung für die Eingabe des Unternehmensbildtitels
     */
    hntTitleThumbnail: LocalizedString
    /**
     * @title Company thumbnail properties placeholder
     * @description Label for the Company thumbnail properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntPropThumbnail: LocalizedString
    /**
     * @title Company video title label
     * @description Label for the Company video title input
     * @title_de TLabel für Firmenvideotitel
     * @description_de Beschriftung für die Eingabe des Unternehmensvideotitels
     */
    hntTitleVideo: LocalizedString
    /**
     * @title Company video properties placeholder
     * @description Placeholder for the Company video properties input
     * @title_de Platzhalter für Unternehmensvideoeigenschaften
     * @description_de Platzhalter für die Eingabe "Unternehmensvideoeigenschaften"
     */
    hntPropVideo: LocalizedString
    /**
     * @title  Browse files Buttonhnt
     * @description Label for the Button Browse files
     * @title_de Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFiles: LocalizedString
    /**
     * @title Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImg: LocalizedString
    /**
     * @title Delete current video Button
     * @description Label for the Button Delete current video
     * @title_de Aktuelles Bild video Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild video
     */
    btnDeleteVideo: LocalizedString
}

export interface BasicInfoSectionConfiguration {
    /**
     * @title Basic Info label
     * @description Label for the Basic Info input
     * @title_de Basisinfo-Etikett
     * @description_de Beschriftung für den Basisinfo-Eingang
     */
    lblBasicInformation: LocalizedString
    /**
     * @title Company label
     * @description Label for the Company input
     * @title_de Firmenlabel
     * @description_de Label für die Unternehmenseingabe
     */
    lblCompany: LocalizedString
    /**
     * @title Company placeholder
     * @description Placeholder for the Company input
     * @title_de Platzhalter für Unternehmen
     * @description_de Platzhalter für die Unternehmenseingabe
     */
    hntCompany: LocalizedString
    /**
     * @title Synonyms label
     * @description Label for the Synonyms input
     * @title_de Synonyme Label
     * @description_de Bezeichnung für die Synonym-Eingabe
     */
    lblSynonyms: LocalizedString
    /**
     * @title Synonyms placeholder
     * @description Placeholder for the Synonyms input
     * @title_de Synonyme Platzhalter
     * @description_de Platzhalter für die Synonyms-Eingabe
     */
    hntSynonyms: LocalizedString
    /**
     * @title Industry label
     * @description Label for the Industry input
     * @title_de Branchenlabel
     * @description_de Label für den Industry Input
     */
    lblIndustry: LocalizedString
    /**
     * @title Industry placeholder
     * @description Placeholder for the Industry input
     * @title_de Branchenplatzhalter
     * @description_de Platzhalter für die Brancheneingabe
     */
    hntIndustry: LocalizedString
    /**
     * @title Phone label
     * @description Label for the Phone input
     * @title_de Telefonetikett
     * @description_de Beschriftung für den Telefoneingang
     */
    lblPhoneNumber: LocalizedString
    /**
     * @title Phone placeholder
     * @description Placeholder for the Phone input
     * @title_de Telefon-Platzhalter
     * @description_de Platzhalter für die Telefoneingabe
     */
    hntPhoneNumber: LocalizedString
    /**
     * @title Mobile label
     * @description Label for the Mobile input
     * @title_de Handy-Label
     * @description_de Label für den Mobile-Eingang
     */
    lblMobile: LocalizedString
    /**
     * @title Mobile placeholder
     * @description Placeholder for the Mobile input
     * @title_de Platzhalter für Mobilgeräte
     * @description_de Platzhalter für die Mobile-Eingabe
     */
    hntMobile: LocalizedString
    /**
     * @title Fax label
     * @description Label for the Fax input
     * @title_de Faxetikett
     * @description_de Label für den Faxeingang
     */
    lblFax: LocalizedString
    /**
     * @title Fax placeholder
     * @description Placeholder for the Fax input
     * @title_de Fax-Platzhalter
     * @description_de Platzhalter für die Faxeingabe
     */
    hntFax: LocalizedString
    /**
     * @title Email label
     * @description Label for the Email input
     * @title_de E-Mail-Label
     * @description_de Label für die E-Mail-Eingabe
     */
    lblEmail: LocalizedString
    /**
     * @title Email placeholder
     * @description Placeholder for the Email input
     * @title_de E-Mail-Platzhalter
     * @description_de Platzhalter für die E-Mail-Eingabe
     */
    hntEmail: LocalizedString
    /**
     * @title Email2 label
     * @description Label for the Email2 input
     * @title_de E-Mail2-Label
     * @description_de Label für die E-Mail2-Eingabe
     */
    lblEmail2: LocalizedString
    /**
     * @title Email2 placeholder
     * @description Placeholder for the Email2 input
     * @title_de E-Mail2-Platzhalter
     * @description_de Platzhalter für die E-Mail2-Eingabe
     */
    hntEmail2: LocalizedString
    /**
     * @title Website label
     * @description Label for the Website input
     * @title_de Website-Label
     * @description_de Label für die Website-Eingabe
     */
    lblWebsite: LocalizedString
    /**
     * @title Website placeholder
     * @description Placeholder for the Website input
     * @title_de Website-Platzhalter
     * @description_de Platzhalter für die Website-Eingabe
     */
    hntWebsite: LocalizedString
    /**
     * @title Address1 label
     * @description Label for the Address1 input
     * @title_de Adressetikett1
     * @description_de Beschriftung für die Adresseingabe1
     */
    lblAddress1: LocalizedString
    /**
     * @title Address1 placeholder
     * @description Placeholder for the Address1 input
     * @title_de Adressplatzhalter1
     * @description_de Platzhalter für die Adresseingabe1
     */
    hntAddress1: LocalizedString
    /**
     * @title Address2 label
     * @description Label for the Address2 input
     * @title_de Adressetikett2
     * @description_de Beschriftung für die Adresseingabe2
     */
    lblAddress2: LocalizedString
    /**
     * @title Address2 placeholder
     * @description Placeholder for the Address2 input
     * @title_de Adressplatzhalter2
     * @description_de Platzhalter für die Adresseingabe2
     */
    hntAddress2: LocalizedString
    /**
     * @title Address3 label
     * @description Label for the Address3 input
     * @title_de Adressetikett3
     * @description_de Beschriftung für die Adresseingabe3
     */
    lblAddress3: LocalizedString
    /**
     * @title Address3 placeholder
     * @description Placeholder for the Address3 input
     * @title_de Adressplatzhalter3
     * @description_de Platzhalter für die Adresseingabe3
     */
    hntAddress3: LocalizedString
    /**
     * @title Zip Code label
     * @description Label for the Zip Code input
     * @title_de Plz-Code-Etikett
     * @description_de Beschriftung für die Postleitzahleingabe
     */
    lblZipCode: LocalizedString
    /**
     * @title Zip Code placeholder
     * @description Placeholder for the Zip Code input
     * @title_de Plz-Platzhalter
     * @description_de Platzhalter für die Postleitzahleingabe
     */
    hntZipCode: LocalizedString
    /**
     * @title City label
     * @description Label for the City input
     * @title_de Stadtlabel
     * @description_de Label für die Eingabe "Stadt"
     */
    lblCity: LocalizedString
    /**
     * @title City placeholder
     * @description Placeholder for the City input
     * @title_de Platzhalter "Stadt"
     * @description_de  Platzhalter für die City-Eingabe
     */
    hntCity: LocalizedString
    /**
     * @title Country label
     * @description Label for the Country input
     * @title_de Länderlabel
     * @description_de Bezeichnung für die Ländereingabe
     */
    lblCountry: LocalizedString
    /**
     * @title Country placeholder
     * @description Placeholder for the Country input
     * @title_de Platzhalter für Land
     * @description_de Platzhalter für die Ländereingabe
     */
    hntCountry: LocalizedString
    /**
     * @title Company Size label
     * @description Label for the Company Size input
     * @title_de Firmengrößenlabel
     * @description_de Beschriftung für die Eingabe der Unternehmensgröße
     */
    lblCompanySize: LocalizedString
    /**
     * @title Company Size placeholder
     * @description Placeholder for the Company Size input
     * @title_de Platzhalter "Unternehmensgröße"
     * @description_de Platzhalter für die Eingabe "Unternehmensgröße"
     */
    hntCompanySize: LocalizedString
    /**
     * @title Founding year label
     * @description Label for the Founding year input
     * @title_de Label für das Gründungsjahr
     * @description_de Label für den Input des Gründungsjahres
     */
    lblFoundingYear: LocalizedString
    /**
     * @title Founding year placeholder
     * @description Placeholder for the Founding year input
     * @title_de Platzhalter für das Gründungsjahr
     * @description_de Platzhalter für die Eingabe des Gründungsjahres
     */
    hntFoundingYear: LocalizedString
}

export interface BasicInfoSectionConfigurationReadonly {
    /**
     * @title Company name readonly
     * @description Readonly for the Name input
     * @title_de Firmenname schreibgeschützt
     * @description_de Nur Lesezugriff für die Eingabe von Name
     */
    companyNameReadonly: boolean
    /**
     * @title Phone Number Readonly
     * @description Readonly for the Phone Number input
     * @title_de Telefonnummer Schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der Rufnummer
     */
    phoneNumberReadonly: boolean
    /**
     * @title Mobile Number Readonly
     * @description Readonly for the Mobile Number input
     * @title_de Handynummer Schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der Handynummer
     */
    mobileNumberReadonly: boolean
    /**
     * @title Fax Readonly
     * @description Readonly for the Fax input
     * @title_de Fax schreibgeschützt
     * @description_de Schreibgeschützt für den Fax-Eingang
     */
    faxReadonly: boolean
    /**
     * @title Email Readonly
     * @description Readonly for the Email input
     * @title_de E-Mail schreibgeschützt
     * @description_de Schreibgeschützt für die E-Mail-Eingabe
     */
    emailReadonly: boolean
    /**
     * @title Website Readonly
     * @description Readonly for the Website input
     * @title_de Website schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der Website
     */
    websiteReadonly: boolean
    /**
     * @title Address1 Readonly
     * @description Readonly for the Address1 input
     * @title_de Address1 schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der Address1
     */
    address1Readonly: boolean
    /**
     * @title Address2 Readonly
     * @description Readonly for the Address2 input
     * @title_de Address2 schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der Address2
     */
    address2Readonly: boolean
    /**
     * @title Address3 Readonly
     * @description Readonly for the Address3 input
     * @title_de Address3 schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der Address3
     */
    address3Readonly: boolean
    /**
     * @title ZipCode Readonly
     * @description Readonly for the ZipCode input
     * @title_de ZipCode schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der ZipCode
     */
    zipCodeReadonly: boolean
    /**
     * @title City Readonly
     * @description Readonly for the City input
     * @title_de City schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der City
     */
    cityReadonly: boolean
    /**
     * @title Country Readonly
     * @description Readonly for the Country input
     * @title_de Country schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der Country
     */
    countryReadonly: boolean
    /**
     * @title Copmany Size Readonly
     * @description Readonly for the Copmany Size input
     * @title_de Copmany Size schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der Copmany Size
     */
    companySizeReadonly: boolean
    /**
     * @title Industry Readonly
     * @description Readonly for the Industry input
     * @title_de Industry schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der Industry
     */
    industryReadonly: boolean
    /**
     * @title FoundingYear Readonly
     * @description Readonly for the FoundingYear input
     * @title_de FoundingYear schreibgeschützt
     * @description_de Schreibgeschützt für die Eingabe der FoundingYear
     */
    foundingYearReadonly: boolean
}

export interface BasicInfoSectionConfigurationMandatory {
    /**
     * @title Company name mandatory
     * @description Mandatory for the Name input
     * @title_de Firmenname obligatorisch
     * @description_de Obligatorisch für die Eingabe des Namens
     */
    companyNameMandatory: boolean
    /**
     * @title Phone Number Mandatory
     * @description Mandatory for the Phone Number input
     * @title_de Telefonnummer Obligatorisch
     * @description_de Obligatorisch für die Eingabe der Rufnummer
     */
    phoneNumberMandatory: boolean
    /**
     * @title Mobile Number Mandatory
     * @description Mandatory for the Mobile Number input
     * @title_de Handynummer Obligatorisch
     * @description_de Obligatorisch für die Eingabe der Handynummer
     */
    mobileNumberMandatory: boolean
    /**
     * @title Fax Mandatory
     * @description Mandatory for the Fax input
     * @title_de Fax Obligatorisch
     * @description_de Obligatorisch für den Fax-Eingang
     */
    faxMandatory: boolean
    /**
     * @title Email Mandatory
     * @description Mandatory for the Email input
     * @title_de E-Mail Obligatorisch
     * @description_de Obligatorisch für die E-Mail-Eingabe
     */
    emailMandatory: boolean
    /**
     * @title Website Mandatory
     * @description Mandatory for the Website input
     * @title_de Website Obligatorisch
     * @description_de Obligatorisch für die Eingabe der Website
     */
    websiteMandatory: boolean
    /**
     * @title Address1 Mandatory
     * @description Mandatory for the Address1 input
     * @title_de Address1 Obligatorisch
     * @description_de Obligatorisch für die Eingabe der Address1
     */
    address1Mandatory: boolean
    /**
     * @title Address2 Mandatory
     * @description Mandatory for the Address2 input
     * @title_de Address2 Obligatorisch
     * @description_de Obligatorisch für die Eingabe der Address2
     */
    address2Mandatory: boolean
    /**
     * @title Address3 Mandatory
     * @description Mandatory for the Address3 input
     * @title_de Address3 Obligatorisch
     * @description_de Obligatorisch für die Eingabe der Address3
     */
    address3Mandatory: boolean
    /**
     * @title ZipCode Mandatory
     * @description Mandatory for the ZipCode input
     * @title_de ZipCode Obligatorisch
     * @description_de Obligatorisch für die Eingabe der ZipCode
     */
    zipCodeMandatory: boolean
    /**
     * @title City Mandatory
     * @description Mandatory for the City input
     * @title_de City Obligatorisch
     * @description_de Obligatorisch für die Eingabe der City
     */
    cityMandatory: boolean
    /**
     * @title Country Mandatory
     * @description Mandatory for the Country input
     * @title_de Country Obligatorisch
     * @description_de Obligatorisch für die Eingabe der Country
     */
    countryMandatory: boolean
    /**
     * @title Copmany Size Mandatory
     * @description Mandatory for the Copmany Size input
     * @title_de Copmany Size Obligatorisch
     * @description_de Obligatorisch für die Eingabe der Copmany Size
     */
    companySizeMandatory: boolean
    /**
     * @title Industry Mandatory
     * @description Mandatory for the Industry input
     * @title_de Industry Obligatorisch
     * @description_de Obligatorisch für die Eingabe der Industry
     */
    industryMandatory: boolean
    /**
     * @title FoundingYear Mandatory
     * @description Mandatory for the FoundingYear input
     * @title_de FoundingYear Obligatorisch
     * @description_de Obligatorisch für die Eingabe der FoundingYear
     */
    foundingYearMandatory: boolean
}

export interface DescriptionSectionConfiguration {
    /**
     * @title Description label
     * @description Label for the Description input
     * @title_de Beschreibungsetikett
     * @description_de Label für den Beschreibungseingang
     */
    lblDescription: LocalizedString
    /**
     * @title Language label
     * @description Label for the Language input
     * @title_de Sprachbezeichnung
     * @description_de Bezeichnung für die Spracheingabe
     */
    lblLanguage: LocalizedString
    /**
     * @title Language placeholder
     * @description Label for the Language input
     * @title_de Platzhalter für Sprache
     * @description_de Bezeichnung für die Spracheingabe
     */
    hntLanguage: LocalizedString
    /**
     * @title Long Description label
     * @description Label for the Long Description input
     * @title_de Lange Beschreibungsbezeichnung
     * @description_de Beschriftung für die Eingabe "Lange Beschreibung"
     */
    lblLongDescription: LocalizedString
    /**
     * @title Long description placeholder
     * @description Placeholder for the Long Description input
     * @title_de Platzhalter mit langer Beschreibung
     * @description_de Platzhalter für die Eingabe "Lange Beschreibung"
     */
    hntLongDescription: LocalizedString
    /**
     * @title Short Description label
     * @description Label for the Short Description input
     * @title_de Kurzbeschreibungsetikett
     * @description_de Beschriftung für die Eingabe "Kurzbeschreibung"
     */
    lblShortDescription: LocalizedString
    /**
     * @title Short description placeholder
     * @description Placeholder for the Short Description input
     * @title_de Kurzbeschreibung Platzhalter
     * @description_de Platzhalter für die Kurzbeschreibungseingabe
     */
    hntShortDescription: LocalizedString
    /**
     * @title Add Translation Button
     * @description Label for the Button Add Translation
     * @title_de Schaltfläche "Übersetzung hinzufügen"
     * @description_de Beschriftung für die Schaltfläche Übersetzung hinzufügen
     */
    btnAddTranslation: LocalizedString
}

export interface KeywordsSectionConfiguration {
    /**
     * @title Keywords title
     * @description Title for the Keywords section
     * @title_de Keywords title
     * @description_de Title for the Keywords section
     */
    title: LocalizedString
    /**
     * @title Keywords label
     * @description Label for the Keywords input
     * @title_de Bezeichnung für Schlüsselwörter
     * @description_de Bezeichnung für die Eingabe Schlüsselwörter
     */
    lblKeywords: LocalizedString
    /**
     * @title Keywords label
     * @description Placeholder for the Keywords input
     * @title_de Bezeichnung für Schlüsselwörter
     * @description_de Platzhalter für die Keywords-Eingabe
     */
    hntKeywords: LocalizedString
}

export enum SocialMediaType {
    FACEBOOK = "FACEBOOK",
    INSTAGRAM = "INSTAGRAM",
    LINKEDIN = "LINKEDIN",
    XING = "XING",
    TWITTER = "TWITTER",
    YOUTUBE = "YOUTUBE",
    PINTEREST = "PINTEREST",
    TIKTOK = "TIKTOK"
}

export interface SocialMediaSectionConfiguration {
    /**
     * @title Social Media label
     * @description Label for the Social Media input
     * @title_de Social-Media-Label
     * @description_de Label für den Social Media Input
     */
    lblSocialMedia: LocalizedString
    /**
     * @title Facebook label
     * @description Label for the Facebook input
     * @title_de Facebook-Label
     * @description_de Label für die Facebook-Eingabe
     */
    lblFacebook: LocalizedString
    /**
     * @title Facebook placeholder
     * @description Placeholder for the Facebook input
     * @title_de Facebook-Platzhalter
     * @description_de Platzhalter für die Facebook-Eingabe
     */
    hntFacebook: LocalizedString
    /**
     * @title Instagram label
     * @description Label for the Instagram input
     * @title_de Instagram-Label
     * @description_de Label für die Instagram-Eingabe
     */
    lblInstagram: LocalizedString
    /**
     * @title Instagram placeholder
     * @description Placeholder for the Instagram input
     * @title_de Instagram-Platzhalter
     * @description_de Platzhalter für die Instagram-Eingabe
     */
    hntInstagram: LocalizedString
    /**
     * @title LinkedIn label
     * @description Label for the LinkedIn input
     * @title_de LinkedIn-Label
     * @description_de Label für die LinkedIn-Eingabe
     */
    lblLinkedIn: LocalizedString
    /**
     * @title LinkedIn placeholder
     * @description Placeholder for the LinkedIn input
     * @title_de LinkedIn-Platzhalter
     * @description_de Platzhalter für die LinkedIn-Eingabe
     */
    hntLinkedIn: LocalizedString
    /**
     * @title Xing label
     * @description Label for the Xing input
     * @title_de Xing-Label
     * @description_de Label für die Xing-Eingabe
     */
    lblXing: LocalizedString
    /**
     * @title Xing placeholder
     * @description Placeholder for the Xing input
     * @title_de Xing-Platzhalter
     * @description_de Platzhalter für die Xing-Eingabe
     */
    hntXing: LocalizedString
    /**
     * @title Twitter label
     * @description Label for the Twitter input
     * @title_de Twitter-Label
     * @description_de Label für die Twitter-Eingabe
     */
    lblTwitter: LocalizedString
    /**
     * @title Twitter placeholder
     * @description Placeholder for the Twitter input
     * @title_de Twitter-Platzhalter
     * @description_de Platzhalter für die Twitter-Eingabe
     */
    hntTwitter: LocalizedString
    /**
     * @title YouTube label
     * @description Label for the YouTube input
     * @title_de YouTube-Label
     * @description_de Label für die YouTube-Eingabe
     */
    lblYouTube: LocalizedString
    /**
     * @title YouTube placeholder
     * @description Placeholder for the YouTube input
     * @title_de YouTube-Platzhalter
     * @description_de Platzhalter für die YouTube-Eingabe
     */
    hntYouTube: LocalizedString
    /**
     * @title Pinterest label
     * @description Label for the Pinterest input
     * @title_de Pinterest-Label
     * @description_de Label für die Pinterest-Eingabe
     */
    lblPinterest: LocalizedString
    /**
     * @title Pinterest placeholder
     * @description Placeholder for the Pinterest input
     * @title_de Pinterest-Platzhalter
     * @description_de Platzhalter für die Pinterest-Eingabe
     */
    hntPinterest: LocalizedString
    /**
     * @title TikTok label
     * @description Label for the TikTok input
     * @title_de TikTok-Label
     * @description_de Label für die TikTok-Eingabe
     */
    lblTikTok: LocalizedString
    /**
     * @title TikTok placeholder
     * @description Placeholder for the TikTok input
     * @title_de TikTok-Platzhalter
     * @description_de Platzhalter für die TikTok-Eingabe
     */
    hntTikTok: LocalizedString
    /**
     * @title Social media order
     * @description Order of social media links in the section, item is not shown if not listed here
     * @title_de Ordnung der Social Media
     * @description_de Reihenfolge der Social-Media-Links im Abschnitt, Element wird nicht angezeigt, wenn es hier nicht aufgeführt ist
     */
    itemsOrder: SocialMediaType[]
}

export interface iFrameSectionConfiguration {
    /**
     * @title iFrame label
     * @description Label for the iFrame  input
     * @title_de Bezeichnung
     * @description_de Bezeichnung für die iFrame-Eingabe
     */
    lbliFrame: LocalizedString
    /**
     * @title URL label
     * @description Label for the iFrame URL input
     * @title_de URL-Bezeichnung
     * @description_de Bezeichnung für die iFrame-URL-Eingabe
     */
    lbliFrameURL: LocalizedString
    /**
     * @title iFrame URL placeholder
     * @description Placeholder for the iFrame URL input
     * @title_de Platzhalter für iFrame-URL
     * @description_de Platzhalter für die iFrame-URL-Eingabe
     */
    hntiFrameUR: LocalizedString
    /**
     * @title iFrame URL DE label
     * @description Label for the iFrame URL DE input
     * @title_de iFrame URL DE Label
     * @description_de Bezeichnung für die iFrame URL DE inputl
     */
    lbliFrameURLDE: LocalizedString
    /**
     * @title iFrame URL DE placeholder
     * @description Placeholder for the iFrame URL DE input
     * @title_de Platzhalter für iFrame URL DE
     * @description_de Platzhalter für die iFrame URL DE-Eingabe
     */
    hntiFrameURLDE: LocalizedString
}

export interface ConsentContentSectionConfiguration {
    /**
     * @title Consent Content label
     * @description Label for the Consent Content input
     * @title_de Label für Einwilligungsinhalt
     * @description_de Label für die Eingabe des Einwilligungsinhalts
     */
    lblConsentContent: LocalizedString
    /**
     * @title Language label
     * @description Label for the Language input
     * @title_de Sprachbezeichnung
     * @description_de Bezeichnung für die Spracheingabe
     */
    lblLanguage: LocalizedString
    /**
     * @title Consent Title label
     * @description Label for the Consent Title input
     * @title_de Bezeichnung "Zustimmungstitel"
     * @description_de Bezeichnung für die Eingabe des Zustimmungstitels
     */
    lblConsentTitle: LocalizedString
    /**
     * @title Content Title placeholder
     * @description Placeholder for the Content Title input
     * @title_de Platzhalter für Inhaltstitel
     * @description_de Platzhalter für die Inhaltstiteleingabe
     */
    hntConsentTitle: LocalizedString
    /**
     * @title Consent Text label
     * @description Label for the Consent Text input
     * @title_de Zustimmung Textbeschriftung
     * @description_de Bezeichnung für die Eingabe des Zustimmungstextes
     */
    lblConsentText: LocalizedString
    /**
     * @title Consent Text placeholder
     * @description Placeholder for the Consent Text input
     * @title_de Platzhalter für Zustimmungstext
     * @description_de Platzhalter für die Eingabe des Zustimmungstextes
     */
    hntConsentText: LocalizedString
}

export interface LinksPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Add Link Button
     * @description Label for the Button Add Link
     * @title_de Schaltfläche "Link hinzufügen"
     * @description_de Beschriftung für den Link Schaltfläche Hinzufügen
     */
    btnAddLink: LocalizedString
    /**
     * @title Add Links Section config
     * @description Label for the Add Links page entry
     * @title_de  Konfiguration des Abschnitts "Links hinzufügen"
     * @description_de  Bezeichnung für den Seiteneintrag "Links hinzufügen"
     */
    sctLink: LinkSectionConfiguration
}

export interface LinkSectionConfiguration {
    /**
     * @title Empty link title
     * @description Label for the empty link title
     * @title_de Empty link title
     * @description_de Label for the empty link title
     */
    titleEmptyLink: LocalizedString
    /**
     * @title URL label
     * @description Label for the URL input
     * @title_de URL-Bezeichnung
     * @description_de Bezeichnung für die URL-Eingabe
     */
    lblURL: LocalizedString
    /**
     * @title URL placeholder
     * @description Placeholder for the URL input
     * @title_de URL-Platzhalter
     * @description_de Platzhalter für die URL-Eingabe
     */
    hntURL: LocalizedString
    /**
     * @title Text label
     * @description Label for the Text input
     * @title_de Textbeschriftung
     * @description_de Beschriftung für die Texteingabe
     */
    lblText: LocalizedString
    /**
     * @title Text placeholder
     * @description Placeholder for the Text input
     * @title_de Textplatzhalter
     * @description_de Platzhalter für die Texteingabe
     */
    hntText: LocalizedString
}

export interface CategoriesPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Category Whitelist
     * @description List of category ids for root elements
     * @title_de Kategorie Whitelist
     * @description_de Liste von Kategorie IDs für Rootelemenete
     */
    categoryWhitelist: string[]
    /**
     * @title Show counter
     * @description Toggle visibility of counter for chosen categories
     * @title_de Zähler anzeigen
     * @description_de Schalten Sie die Sichtbarkeit des Zählers für ausgewählte Kategorien um
     */
    showCounter: boolean
}

export interface LicencesPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Category Whitelist
     * @description List of category ids for root elements
     * @title_de Kategorie Whitelist
     * @description_de Liste von Kategorie IDs für Rootelemenete
     */
    categoryWhitelist: string[]
    /**
     * @title Licences page visible
     * @description Licences page configuration for page visibility
     * @title_de Lizenzen Seite sichtbar
     * @description_de Konfiguration der "Lizenzen" für die Sichtbarkeit der Seite
     */
    licencesPageVisible: boolean
}

export interface ProductsPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Add Product Button
     * @description Label for the Button Add Product
     * @title_de Schaltfläche "Produkt hinzufügen"
     * @description_de Beschriftung für die Schaltfläche Produkt hinzufügen
     */
    btnAddProduct: LocalizedString
    /**
     * @title Add Product Section config
     * @description Label for the Add Product profile page entry
     * @title_de  Produktabschnittskonfiguration hinzufügen
     * @description_de Bezeichnung für den Seiteneintrag Produktprofil hinzufügen
     */
    sctProduct: ProductSectionConfiguration
}

export interface ProductSectionConfiguration {
    /**
     * @title Empty product title
     * @description Label for the empty Product title
     * @title_de Empty product title
     * @description_de Label for the empty Product title
     */
    titleEmptyProduct: LocalizedString
    /**
     * @title Product image label
     * @description Label for the Product image
     * @title_de Produktbild-Etikett
     * @description_de Etikett für das Produktbild
     */
    lblProductImage: LocalizedString
    /**
     * @title Product image message label
     * @description Label for the Product image message label input
     * @title_de Etikett der Produktbildnachricht
     * @description_de Etikett für die Eingabe der Produktbildnachricht
     */
    lblProductImageMessage: LocalizedString
    /**
     * @title Product background upload visible
     * @description Toggle the visibility of product background upload
     * @title_de Produkthintergrund-Upload sichtbar
     * @description_de Schalten Sie die Sichtbarkeit des Produkthintergrund-Uploads um
     */
    showProductBackgroundUpload: boolean
    /**
     * @title Product Background placeholder
     * @description Label for the Product Background input
     * @title_de Platzhalter "Produkthintergrund"
     * @description_de Beschriftung für die Eingabe "Produkthintergrund"
     */
    lblProductBackground: LocalizedString
    /**
     * @title Product Image Title label
     * @description Label for the Product Image Title input
     * @title_de Titelbezeichnung des Produktbilds
     * @description_de Beschriftung für die Eingabe des Produktbildtitels
     */
    hntTitle: LocalizedString
    /**
     * @title Product Background Title label
     * @description Label for the Product Background Title input
     * @title_de Titelbezeichnung des phintergrundes
     * @description_de Beschriftung für die Eingabe des Produkthintergrundtitels
     */
    hntTitleBackground: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Label for the Company Image Properties input
     * @title_de Eigenschaften des Produktbilds
     * @description_de Bezeichnung für die Eingabe "Produktbildeigenschaften"
     */
    hntProp: LocalizedString
    /**
     * @title Product Background Properties placeholder
     * @description Label for the Product Background Properties input
     * @title_de Eigenschaften des Produkthintergrund
     * @description_de Bezeichnung für die Eingabe "Produkthintergrundeigenschaften"
     */
    hntPropBackground: LocalizedString
    /**
     * @title  Browse files Button
     * @description Label for the Button Browse files
     * @title_de Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFiles: LocalizedString
    /**
     * @title  Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImg: LocalizedString
    /**
     * @title Logo Browse files Button
     * @description Label for the Button Browse files
     * @title_de Logo Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFilesBackground: LocalizedString
    /**
     * @title Logo Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Logo Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImgBackground: LocalizedString
    /**
     * @title Product name label
     * @description Label for the Product name label input
     * @title_de  Etikett des Produktnamens
     * @description_de Etikett für die Eingabe des Produktnamensetiketts
     */
    lblProductname: LocalizedString
    /**
     * @title Product name placeholder
     * @description Placeholder for the Product name input
     * @title_de Platzhalter für Produktname
     * @description_de Platzhalter für die Eingabe des Produktnamens
     */
    hntProductname: LocalizedString
    /**
     * @title Start date label
     * @description Label for the Start date input
     * @title_de Etikett mit Startdatum
     * @description_de Etikett für die Eingabe des Startdatums
     */
    lblProductPublicationStart: LocalizedString
    /**
     * @title Start date placeholder
     * @description Placeholder for the Start date
     * @title_de Lautsprecher Label
     * @description_de Bezeichnung für die Eingabe Lautsprecher
     */
    hntProductPublicationStart: LocalizedString
    /**
     * @title End date label
     * @description Label for the End date input
     * @title_de Etikett mit Enddatum
     * @description_de Etikett für die Eingabe des Enddatums
     */
    lblProductPublicationEnd: LocalizedString
    /**
     * @title End date plaecholder
     * @description Placeholder for the End date
     * @title_de Lautsprecher Label
     * @description_de Bezeichnung für die Eingabe Lautsprecher
     */
    hntProductPublicationEnd: LocalizedString
    /**
     * @title Description for the detail page label
     * @description Label for the Description for the detail page label input
     * @title_de Beschreibung für die Detailseitenbeschriftung
     * @description_de Beschriftung für die Beschreibung für die Detailseitenbeschriftungseingabe
     */
    lblDescriptionDetail: LocalizedString
    /**
     * @title Description for the detail page placeholder
     * @description Placeholder for the Description for the detail page input
     * @title_de Beschreibung für den Platzhalter der Detailseite
     * @description_de Platzhalter für die Beschreibung für die Detailseiteneingabe
     */
    hntDescriptionDetail: LocalizedString
    /**
     * @title Short description for the list view label
     * @description Label for the Short description for the list view label input
     * @title_de Kurzbeschreibung für die Listenansichtsbeschriftung
     * @description_de Beschriftung für die Kurzbeschreibung für die Listenansichtsbeschriftungseingabe
     */
    lblDescriptionShort: LocalizedString
    /**
     * @title Short description for the list view placeholder
     * @description Placeholder Short description for the list view input
     * @title_de Kurzbeschreibung für den Platzhalter der Listenansicht
     * @description_de Platzhalter Kurzbeschreibung für die Listenansichtseingabe
     */
    hntDescriptionShort: LocalizedString
    /**
     * @title Product Information label
     * @description Label for the Product Information input
     * @title_de Produktinformationsetikett
     * @description_de Etikett für die Eingabe der Produktinformation
     */
    lblTabProductInformation: LocalizedString
    /**
     * @title Categories label
     * @description Label for the Categories input
     * @title_de Kategorien Label
     * @description_de Bezeichnung für die Eingabe Kategorien
     */
    lblTabCategories: LocalizedString
    /**
     * @title Multimedia label
     * @description Label for the Multimedia input
     * @title_de Multimedia-Label
     * @description_de Beschriftung für den Multimedia-Eingang
     */
    lblTabMultimedia: LocalizedString
    /**
     * @title Keywords label
     * @description Label for the Keywords input
     * @title_de Bezeichnung für Schlüsselwörter
     * @description_de Bezeichnung für die Eingabe Schlüsselwörter
     */
    lblTabKeywords: LocalizedString
    /**
     * @title Product Information Tab Description
     * @description Label for Product Information Tab Description
     * @title_de Registerkarte Produktinformation Beschreibung
     * @description_de Etikett für die Registerkarte Produktinformation Beschreibung
     */
    lblProductInformationDescription: LocalizedString
    /**
     * @title Categories Tab Description
     * @description Label for Categories Tab Description
     * @title_de Categories Tab Description
     * @description_de Label for Categories Tab Description
     */
    lblCategoriesDescription: LocalizedString
    /**
     * @title Multimedia Tab Description
     * @description Label for Multimedia Tab Description
     * @title_de Multimedia Tab Description
     * @description_de Label for Multimedia Tab Description
     */
    lblMultimediaDescription: LocalizedString
    /**
     * @title Category Whitelist
     * @description List of category ids for root elements
     * @title_de Kategorie Whitelist
     * @description_de Liste von Kategorie IDs für Rootelemenete
     */
    categoryWhitelist: string[]
    /**
     * @title Multimedia files limit
     * @description Maximum number of files one can upload in the Multimedia tab
     * @title_de --
     * @description_de --
     */
    multimediaLimit: number
    /**
     * @title Product Type label
     * @description Label for Product Type field Description
     * @title_de --
     * @description_de --
     */
    productType: LocalizedString
    /**
     * @title Product Type placeholder
     * @description Product Type field placeholder
     * @title_de --
     * @description_de --
     */
    productTypePlaceholder: LocalizedString
    /**
     * @title Product property Value label
     * @description Product property Value field label
     * @title_de --
     * @description_de --
     */
    lblPropertyValue: LocalizedString
    /**
     * @title Product property value placeholder
     * @description Product property value field placeholder
     * @title_de --
     * @description_de --
     */
    propertyValuePlaceholder: LocalizedString
}

export interface TrademarksPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Add Trademark Button
     * @description Label for the Button Add Trademark
     * @title_de Schaltfläche "Marke hinzufügen"
     * @description_de Beschriftung für die Schaltfläche Marke hinzufügen
     */
    btnAddTrademark: LocalizedString
    /**
     * @title Add Trademark Section config
     * @description Label for the Add Trademark profile page entry
     * @title_de Konfiguration des Markenabschnitts hinzufügen
     * @description_de Label für den Eintrag auf der Profilseite Marke hinzufügen
     */
    sctTrademark: TrademarkSectionConfiguration
}

export interface TrademarkSectionConfiguration {
    /**
     * @title Empty Trademark title
     * @description Label for the empty Trademark title
     * @title_de Empty Trademark title
     * @description_de Label for the empty Trademark title
     */
    titleEmptyTrademark: LocalizedString
    /**
     * @title Trademark image label
     * @description Label for the Trademark image
     * @title_de Markenbildlabel
     * @description_de Label für das Markenbild
     */
    lblTrademarkImage: LocalizedString
    /**
     * @title Trademark image message label
     * @description Label for the Trademark image message label input
     * @title_de Label für Markenbildnachrichten
     * @description_de Label für die Label-Eingabe für Markenbildnachrichten
     */
    lblTrademarkImageMessage: LocalizedString
    /**
     * @title Company Image Title label
     * @description Label for the Company Image Title input
     * @title_de Titelbezeichnung des Firmenbilds
     * @description_de Beschriftung für die Eingabe des Unternehmensbildtitels
     */
    hntTitle: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Label for the Company Image Properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntProp: LocalizedString
    /**
     * @title  Browse files Button
     * @description Label for the Button Browse files
     * @title_de Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFiles: LocalizedString
    /**
     * @title  Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImg: LocalizedString
    /**
     * @title Trademark name label
     * @description Label for the Trademark name label input
     * @title_de  Markennamensetikett
     * @description_de Label für die Eingabe des Markennamen-Labels
     */
    lblTrademarkname: LocalizedString
    /**
     * @title Trademark name placeholder
     * @description Placeholder for the Trademark name input
     * @title_de Platzhalter für Markennamen
     * @description_de Platzhalter für die Eingabe des Markennamens
     */
    hntTrademarkname: LocalizedString
    /**
     * @title Description for the detail page label
     * @description Label for the Description for the detail page label input
     * @title_de Beschreibung für die Detailseitenbeschriftung
     * @description_de Beschriftung für die Beschreibung für die Detailseitenbeschriftungseingabe
     */
    lblDescriptionDetail: LocalizedString
    /**
     * @title Description for the detail page placeholder
     * @description Placeholder for the Description for the detail page input
     * @title_de Beschreibung für den Platzhalter der Detailseite
     * @description_de Platzhalter für die Beschreibung für die Detailseiteneingabe
     */
    hntDescriptionDetail: LocalizedString
    /**
     * @title Short description for the list view label
     * @description Label for the Short description for the list view label input
     * @title_de Kurzbeschreibung für die Listenansichtsbeschriftung
     * @description_de Beschriftung für die Kurzbeschreibung für die Listenansichtsbeschriftungseingabe
     */
    lblDescriptionShort: LocalizedString
    /**
     * @title Short description for the list view placeholder
     * @description Placeholder Short description for the list view input
     * @title_de Kurzbeschreibung für den Platzhalter der Listenansicht
     * @description_de Platzhalter Kurzbeschreibung für die Listenansichtseingabe
     */
    hntDescriptionShort: LocalizedString
    /**
     * @title Categories label
     * @description Label for the Categories input
     * @title_de Kategorien Label
     * @description_de Bezeichnung für die Eingabe Kategorien
     */
    lblTabCategories: LocalizedString
    /**
     * @title Multimedia label
     * @description Label for the Multimedia input
     * @title_de Multimedia-Label
     * @description_de Beschriftung für den Multimedia-Eingang
     */
    lblTabMultimedia: LocalizedString
    /**
     * @title Keywords label
     * @description Label for the Keywords input
     * @title_de Bezeichnung für Schlüsselwörter
     * @description_de Bezeichnung für die Eingabe Schlüsselwörter
     */
    lblTabKeywords: LocalizedString
    /**
     * @title Categories Tab Description
     * @description Label for Categories Tab Description
     * @title_de Categories Tab Description
     * @description_de Label for Categories Tab Description
     */
    lblCategoriesDescription: LocalizedString
    /**
     * @title Multimedia Tab Description
     * @description Label for Multimedia Tab Description
     * @title_de Multimedia Tab Description
     * @description_de Label for Multimedia Tab Description
     */
    lblMultimediaDescription: LocalizedString
    /**
     * @title Category Whitelist
     * @description List of category ids for root elements
     * @title_de Kategorie Whitelist
     * @description_de Liste von Kategorie IDs für Rootelemenete
     */
    categoryWhitelist: string[]
    /**
     * @title Multimedia files limit
     * @description Maximum number of files one can upload in the Multimedia tab
     * @title_de --
     * @description_de --
     */
    multimediaLimit: number
}

export interface TeamPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Add Staff Button
     * @description Label for the Button Add Staff
     * @title_de Schaltfläche „Personal hinzufügen“
     * @description_de Beschriftung für die Schaltfläche Notensystem hinzufügen
     */
    btnAddSPerson: LocalizedString
    /**
     * @title Add Staff Section config
     * @description Label for the Add Staff profile page entry
     * @title_de Konfiguration des Personalbereichs hinzufügen
     * @description_de Bezeichnung für den Profilseiteneintrag Mitarbeiter hinzufügen
     */
    sctPerson: PersonSectionConfiguration
}

export interface PersonSectionConfiguration {
    /**
     * @title Empty Person title
     * @description Label for the empty Person title
     * @title_de Empty Person title
     * @description_de Label for the empty Person title
     */
    titleEmptyPerson: LocalizedString
    /**
     * @title Profile image label
     * @description Label for the Profile image
     * @title_de Profilbild-Etikett
     * @description_de Label für das Profilbild
     */
    lblProfileImage: LocalizedString
    /**
     * @title Profile image message label
     * @description Label for the Trademark image message label input
     * @title_de Label für Markenbildnachrichten
     * @description_de Label für die Label-Eingabe für Markenbildnachrichten
     */
    lblProfileImageMessage: LocalizedString
    /**
     * @title Company Image Title label
     * @description Label for the Company Image Title input
     * @title_de Titelbezeichnung des Firmenbilds
     * @description_de Beschriftung für die Eingabe des Unternehmensbildtitels
     */
    hntTitle: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Label for the Company Image Properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntProp: LocalizedString
    /**
     * @title  Browse files Button
     * @description Label for the Button Browse files
     * @title_de Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFiles: LocalizedString
    /**
     * @title  Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImg: LocalizedString
    /**
     * @title Title label
     * @description Label for the Title label input
     * @title_de  Titellabel
     * @description_de Label für die Titellabel-Eingabe
     */
    lblTitleTeam: LocalizedString
    /**
     * @title  Title name placeholder
     * @description Placeholder for the Title name input
     * @title_de Platzhalter für den Titelnamen
     * @description_de Platzhalter für die Eingabe des Titelnamens
     */
    hntTitleTeam: LocalizedString
    /**
     * @title Last name label
     * @description Label for the Last namelabel input
     * @title_de  Nachname-Etikett
     * @description_de Label für die Eingabe des Nachnamenlabels
     */
    lblLastName: LocalizedString
    /**
     * @title  Last name placeholder
     * @description Placeholder for the Last name input
     * @title_de Platzhalter für den Nachnamen
     * @description_de Platzhalter für die Eingabe des Nachnamens
     */
    hntLastName: LocalizedString
    /**
     * @title First name label
     * @description Label for the First name label input
     * @title_de  Etikett mit Vornamen
     * @description_de Beschriftung für die Beschriftungseingabe Vorname
     */
    lblFirstName: LocalizedString
    /**
     * @title  First name placeholder
     * @description Placeholder for the First name input
     * @title_de Platzhalter für den Vornamen
     * @description_de Platzhalter für die Eingabe des Vornamens
     */
    hntFirstName: LocalizedString
    /**
     * @title Role for this event label
     * @description Label for the Role for this event label input
     * @title_de  Rolle für dieses Ereignislabel
     * @description_de Label für die Rolle für diese Ereignislabeleingabe
     */
    lblRole: LocalizedString
    /**
     * @title  Role for this event dropdown
     * @description Dropdown for Role for this event
     * @title_de Rolle für dieses Ereignis-Dropdown-Menü
     * @description_de Dropdown für Rolle für dieses Ereignis
     */
    hntRole: LocalizedString
    /**
     * @title Position label
     * @description Label for the Position label input
     * @title_de  Positionsbezeichnung
     * @description_de Label für die Positionslabel-Eingabe
     */
    lblPosition: LocalizedString
    /**
     * @title  Position placeholder
     * @description Placeholder for the Position input
     * @title_de Platzhalter positionieren
     * @description_de Platzhalter für die Positionseingabe
     */
    hntPosition: LocalizedString
    /**
     * @title Company label
     * @description Label for the Company label input
     * @title_de  Firmenlabel
     * @description_de Label für die Eingabe des Firmenlabels
     */
    lblCompany: LocalizedString
    /**
     * @title  Company placeholder
     * @description Placeholder for the Company input
     * @title_de Platzhalter für Unternehmen
     * @description_de Platzhalter für die Firmeneingabe
     */
    hntCompany: LocalizedString
    /**
     * @title Phone label
     * @description Label for the Phone label input
     * @title_de  Telefonetikett
     * @description_de Label für die Eingabe des Telefonlabels
     */
    lblPhone: LocalizedString
    /**
     * @title  Phone placeholder
     * @description Placeholder for the Phone input
     * @title_de Telefon-Platzhalter
     * @description_de Platzhalter für die Telefoneingabe
     */
    hntPhone: LocalizedString
    /**
     * @title Email label
     * @description Label for the Email label input
     * @title_de  E-Mail-Label
     * @description_de Label für die E-Mail-Label-Eingabe
     */
    lblEmail: LocalizedString
    /**
     * @title  Email placeholder
     * @description Placeholder for the Email input
     * @title_de E-Mail-Platzhalter
     * @description_de Platzhalter für die E-Mail-Eingabe
     */
    hntEmail: LocalizedString
    /**
     * @title Description for the detail page label
     * @description Label for the Description for the detail page label input
     * @title_de Beschreibung für die Detailseitenbeschriftung
     * @description_de Beschriftung für die Beschreibung für die Detailseitenbeschriftungseingabe
     */
    lblDescriptionDetail: LocalizedString
    /**
     * @title Description for the detail page placeholder
     * @description Placeholder for the Description for the detail page input
     * @title_de Beschreibung für den Platzhalter der Detailseite
     * @description_de Platzhalter für die Beschreibung für die Detailseiteneingabe
     */
    hntDescriptionDetail: LocalizedString
    /**
     * @title Short description for the list view label
     * @description Label for the Short description for the list view label input
     * @title_de Kurzbeschreibung für die Listenansichtsbeschriftung
     * @description_de Beschriftung für die Kurzbeschreibung für die Listenansichtsbeschriftungseingabe
     */
    lblDescriptionShort: LocalizedString
    /**
     * @title Short description for the list view placeholder
     * @description Placeholder Short description for the list view input
     * @title_de Kurzbeschreibung für den Platzhalter der Listenansicht
     * @description_de Platzhalter Kurzbeschreibung für die Listenansichtseingabe
     */
    hntDescriptionShort: LocalizedString
    /**
     * @title Phone visible label
     * @description Label for the Phone visible checkbox
     * @title_de  Phone visible label
     * @description_de Label for the Phone visible checkbox
     */
    lblPhoneVisible: LocalizedString
    /**
     * @title Email visible label
     * @description Label for the Email visible checkbox
     * @title_de  Email visible label
     * @description_de Label for the Email visible checkbox
     */
    lblEmailVisible: LocalizedString
    /**
     * @title Person functions Whitelist
     * @description List of person function ids for contact persons
     * @title_de Kategorie Whitelist
     * @description_de Liste der Personenfunktions-IDs für für Ansprechpartner
     */
    personFunctionWhitelist: string[]
}

export interface MultimediaPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Multimedia Section config
     * @description Label for the Multimedia section page entry
     * @title_de Konfiguration des Multimedia-Bereichs
     * @description_de Label für den Seiteneintrag Multimedia-Bereich
     */
    sctMultimediaImage: MultimediaImageSectionConfiguration
}

export interface MultimediaImageSectionConfiguration {
    /**
     * @title Company Image Title label
     * @description Placeholder for the Company Image Title input
     * @title_de Titelbezeichnung des Firmenbilds
     * @description_de Beschriftung für die Eingabe des Unternehmensbildtitels
     */
    hntDropzoneTitle: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Placeholder for the Company Image Properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntDropzoneProp: LocalizedString
    /**
     * @title Position  label
     * @description Label for the Position label input
     * @title_de Positionsbezeichnung
     * @description_de Label für die Positionslabel-Eingabe
     */
    lblHeaderPosition: LocalizedString
    /**
     * @title Preview  label
     * @description Label for the Preview label input
     * @title_de Etikettenvorschau
     * @description_de Label für die Label-Eingabe in der Vorschau
     */
    lblHeaderPreview: LocalizedString
    /**
     * @title File  label
     * @description Label for the File label input
     * @title_de Dateibezeichnung
     * @description_de Label für die Dateilabel-Eingabe
     */
    lblHeaderFile: LocalizedString
    /**
     * @title  Title placeholder
     * @description Placeholder for the Title input
     * @title_de Bildtitellabel
     * @description_de Platzhalter für die Bildtiteleingabe
     */
    hntTitle: LocalizedString
    /**
     * @title Subtitle placeholder
     * @description Placeholder for the Subtitle input
     * @title_de Untertitel-Platzhalter
     * @description_de Platzhalter für die Untertiteleingabe
     */
    hntSubtitle: LocalizedString
}

export interface NewsPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Add News Button
     * @description Label for the Button Add News
     * @title_de Schaltfläche "Neuigkeiten hinzufügen"
     * @description_de Beschriftung für den Button News hinzufügen
     */
    btnAddNews: LocalizedString
    /**
     * @title Add News Section config
     * @description Label for the Add News profile page entry
     * @title_de Konfiguration des Nachrichtenbereichs hinzufügen
     * @description_de Label für den Eintrag auf der Profilseite "News hinzufügen"
     */
    sctNews: NewsSectionConfiguration
}

export interface NewsSectionConfiguration {
    /**
     * @title Empty News title
     * @description Label for the empty News title
     * @title_de Empty News title
     * @description_de Label for the empty News title
     */
    titleEmptyNews: LocalizedString
    /**
     * @title News image label
     * @description Label for the News image
     * @title_de Nachrichten-Bildlabel
     * @description_de Label für das Nachrichtenbild
     */
    lblNewsImage: LocalizedString
    /**
     * @title News image message label
     * @description Label for the News image message label input
     * @title_de Nachrichtenlabel für Nachrichtenbild
     * @description_de Label für die Label-Eingabe für Nachrichten-Bildnachrichten
     */
    lblNewsImageMessage: LocalizedString
    /**
     * @title News Image Title label
     * @description Label for the News Image Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntTitle: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Label for the Company Image Properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntProp: LocalizedString
    /**
     * @title  Browse files Button
     * @description Label for the Button Browse files
     * @title_de Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFiles: LocalizedString
    /**
     * @title  Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImg: LocalizedString
    /**
     * @title News title label
     * @description Label for the News title label input
     * @title_de  Titellabel der Nachrichten
     * @description_de Label für die Eingabe des Titellabels für Nachrichten
     */
    lblNewsTitle: LocalizedString
    /**
     * @title  News title placeholder
     * @description Placeholder for the  News title input
     * @title_de Platzhalter für Nachrichtentitel
     * @description_de Platzhalter für die Eingabe des Nachrichtentitels
     */
    hntNewsTitle: LocalizedString
    /**
     * @title News date label
     * @description Label for the News date label input
     * @title_de  Datumsetikett für Nachrichten
     * @description_de Label für die Eingabe des News-Datumslabels
     */
    lblNewsDate: LocalizedString
    /**
     * @title  News date placeholder
     * @description Placeholder for the  News date input
     * @title_de Platzhalter für das Nachrichtendatum
     * @description_de Platzhalter für die Eingabe des Nachrichtendatums
     */
    hntNewsDate: LocalizedString
    /**
     * @title Description  label
     * @description Label for the Description  label input
     * @title_de Beschreibungsetikett
     * @description_de Beschriftung für die Beschriftungseingabe Beschreibung
     */
    lblDescription: LocalizedString
    /**
     * @title Description placeholder
     * @description Placeholder for the Description input
     * @title_de Beschreibung Platzhalter
     * @description_de Platzhalter für die Eingabe Beschreibung
     */
    hntDescription: LocalizedString
    /**
     * @title Subtitle label
     * @description Label for the Subtitle label input
     * @title_de Untertitel-Label
     * @description_de Label für die Untertitel-Label-Eingabe
     */
    lblSubtitle: LocalizedString
    /**
     * @title Subtitle placeholder
     * @description Placeholder Subtitle input
     * @title_de Untertitel-Platzhalter
     * @description_de Platzhalter-Untertiteleingabe
     */
    hntSubtitle: LocalizedString
    /**
     * @title Categories label
     * @description Label for the Categories input
     * @title_de Kategorien Label
     * @description_de Bezeichnung für die Eingabe Kategorien
     */
    lblTabCategories: LocalizedString
    /**
     * @title Multimedia label
     * @description Label for the Multimedia input
     * @title_de Multimedia-Label
     * @description_de Beschriftung für den Multimedia-Eingang
     */
    lblTabMultimedia: LocalizedString
    /**
     * @title Keywords label
     * @description Label for the Keywords input
     * @title_de Bezeichnung für Schlüsselwörter
     * @description_de Bezeichnung für die Eingabe Schlüsselwörter
     */
    lblTabKeywords: LocalizedString
    /**
     * @title Categories Tab Description
     * @description Label for Categories Tab Description
     * @title_de Categories Tab Description
     * @description_de Label for Categories Tab Description
     */
    lblCategoriesDescription: LocalizedString
    /**
     * @title Multimedia Tab Description
     * @description Label for Multimedia Tab Description
     * @title_de Multimedia Tab Description
     * @description_de Label for Multimedia Tab Description
     */
    lblMultimediaDescription: LocalizedString
    /**
     * @title Category Whitelist
     * @description List of category ids for root elements
     * @title_de Kategorie Whitelist
     * @description_de Liste von Kategorie IDs für Rootelemenete
     */
    categoryWhitelist: string[]
    /**
     * @title Multimedia files limit
     * @description Maximum number of files one can upload in the Multimedia tab
     * @title_de --
     * @description_de --
     */
    multimediaLimit: number
}

export interface EventDatesPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Add event date Button
     * @description Label for the Button Add event date
     * @title_de Schaltfläche "Ereignisdatum hinzufügen"
     * @description_de Beschriftung für die Schaltfläche Ereignisdatum hinzufügen
     */
    btnAddEventDate: LocalizedString
    /**
     * @title Add event date Section config
     * @description Label for the Add event date profile page entry
     * @title_de Ereignisdatum hinzufügen Abschnittskonfiguration
     * @description_de Label für den Profilseiteneintrag Ereignisdatum hinzufügen
     */
    sctEventDates: EventDatesSectionConfiguration
}

export interface EventDatesSectionConfiguration {
    /**
     * @title Empty Event date title
     * @description Label for the empty Event date title
     * @title_de Empty Event date title
     * @description_de Label for the empty Event date title
     */
    titleEmptyEventDate: LocalizedString
    /**
     * @title Event date image label
     * @description Label for the Event Date image
     * @title_de Bildlabel für Ereignisdatum
     * @description_de Label für das Bild des Veranstaltungsdatums
     */
    lblEventDateImage: LocalizedString
    /**
     * @title Event date image message label
     * @description Label for the Event date image message label input
     * @title_de Etikett der Bildnachricht des Ereignisdatums
     * @description_de Label for the Event date image message label input
     */
    lblEventDateImageMessage: LocalizedString
    /**
     * @title News Image Title label
     * @description Label for the News Image Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntTitle: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Label for the Company Image Properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntProp: LocalizedString
    /**
     * @title  Browse files Button
     * @description Label for the Button Browse files
     * @title_de Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFiles: LocalizedString
    /**
     * @title  Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImg: LocalizedString
    /**
     * @title Event date title label
     * @description Label for the Event date title label input
     * @title_de Titelbezeichnung des Veranstaltungsdatums
     * @description_de Label für die Eingabe des Titellabels für das Ereignisdatum
     */
    lblEventdateTitle: LocalizedString
    /**
     * @title  Event date title placeholder
     * @description Placeholder for the  Event date title input
     * @title_de Platzhalter für den Titel des Veranstaltungsdatums
     * @description_de Platzhalter für die Eingabe des Ereignisdatumstitels
     */
    hntEventdateTitle: LocalizedString
    /**
     * @title Event date date label
     * @description Label for the Event date date label input
     * @title_de  Datumsetikett des Veranstaltungsdatums
     * @description_de Beschriftung für die Beschriftungseingabe für das Datum des Ereignisdatums
     */
    lblDate: LocalizedString
    /**
     * @title  Event date date placeholder
     * @description Placeholder for the  Event date date input
     * @title_de Platzhalter für das Datum des Ereignisses
     * @description_de Platzhalter für die Datumseingabe des Ereignisdatums
     */
    hntDate: LocalizedString
    /**
     * @title Event date time label
     * @description Label for the Event date time label input
     * @title_de  Datum und Uhrzeit des Ereignisses
     * @description_de Label für die Eingabe des Ereignis-Datum-Uhrzeit-Labels
     */
    lblTime: LocalizedString
    /**
     * @title  Event date time placeholder
     * @description Placeholder for the  Event date time input
     * @title_de Platzhalter für Datum und Uhrzeit des Ereignisses
     * @description_de Platzhalter für die Eingabe von Ereignisdatum und Uhrzeit
     */
    hntTime: LocalizedString
    /**
     * @title Event date Location label
     * @description Label for the Event date Location label input
     * @title_de  Veranstaltungsdatum Standortlabel
     * @description_de Label für das Event-Datum Ort-Label-Eingabe
     */
    lblLocation: LocalizedString
    /**
     * @title  Event date Location placeholder
     * @description Placeholder for the  Event date Location input
     * @title_de Veranstaltungsdatum Ort Platzhalter
     * @description_de Platzhalter für die Eingabe des Veranstaltungsdatums Ort
     */
    hntLocation: LocalizedString
    /**
     * @title Event type label
     * @description Label for the Event type label input
     * @title_de  Label des Ereignistyps
     * @description_de Label für die Eingabe des Ereignistyp-Labels
     */
    lblEventType: LocalizedString
    /**
     * @title  Event type placeholder
     * @description Placeholder for the  Event type input
     * @title_de Platzhalter für Ereignistyp
     * @description_de Platzhalter für die Eingabe des Ereignistyps
     */
    hntEventType: LocalizedString
    /**
     * @title Part of event series label
     * @description Label for thePart of event series label input
     * @title_de  Teil des Labels der Veranstaltungsreihe
     * @description_de Beschriftung für die Beschriftungseingabe "Teil der Ereignisreihe"
     */
    lblPartEventSeries: LocalizedString
    /**
     * @title  Part of event series placeholder
     * @description Placeholder for the Part of event series input
     * @title_de Teil des Platzhalters für Veranstaltungsreihen
     * @description_de Platzhalter für die Eingabe Teil der Veranstaltungsreihe
     */
    hntPartEventSeries: LocalizedString
    /**
     * @title Listen on label
     * @description Label for the Listen on label input
     * @title_de Auf Etikett anhören
     * @description_de Label für die Listen-on-Label-Eingabe
     */
    lblListenon: LocalizedString
    /**
     * @title Listen on label
     * @description Label for the Listen on label input
     * @title_de Auf Etikett anhören
     * @description_de Label für die Listen-on-Label-Eingabe
     */
    lblListenonTRUE: LocalizedString
    /**
     * @title Listen on label
     * @description Label for the Listen on label input
     * @title_de Auf Etikett anhören
     * @description_de Label für die Listen-on-Label-Eingabe
     */
    lblListenonFALSE: LocalizedString
    /**
     * @title  Listen on placeholder
     * @description Placeholder for the  Listen on input
     * @title_de Platzhalter anhören
     * @description_de Platzhalter für das Listen on input
     */
    hntListenon: LocalizedString
    /**
     * @title Breakout Room
     * @description Label for the Breakout Room  label input
     * @title_de Breakout-Raum
     * @description_de Beschriftung für die Breakout-Raum Beschreibung
     */
    lblBreakoutRoom: LocalizedString
    /**
     * @title Description  label
     * @description Label for the Description  label input
     * @title_de Beschreibungsetikett
     * @description_de Beschriftung für die Beschriftungseingabe Beschreibung
     */
    lblDescription: LocalizedString
    /**
     * @title Description placeholder
     * @description Placeholder for the Description input
     * @title_de Beschreibung Platzhalter
     * @description_de Platzhalter für die Eingabe Beschreibung
     */
    hntDescription: LocalizedString
    /**
     * @title Subtitle label
     * @description Label for the Subtitle label input
     * @title_de Untertitel-Label
     * @description_de Label für die Untertitel-Label-Eingabe
     */
    lblSubtitle: LocalizedString
    /**
     * @title Subtitle placeholder
     * @description Placeholder Subtitle input
     * @title_de Untertitel-Platzhalter
     * @description_de Platzhalter-Untertiteleingabe
     */
    hntSubtitle: LocalizedString
    /**
     * @title Speakers label
     * @description Label for the Speakers input
     * @title_de Lautsprecher Label
     * @description_de Bezeichnung für die Eingabe Lautsprecher
     */
    lblTabSpeakers: LocalizedString
    /**
     * @title Multimedia label
     * @description Label for the Multimedia input
     * @title_de Multimedia-Label
     * @description_de Beschriftung für den Multimedia-Eingang
     */
    lblTabMultimedia: LocalizedString
    /**
     * @title Keywords label
     * @description Label for the Keywords input
     * @title_de Bezeichnung für Schlüsselwörter
     * @description_de Bezeichnung für die Eingabe Schlüsselwörter
     */
    lblTabKeywords: LocalizedString
    /**
     * @title Prerecorded label
     * @description Label for the Prerecorded input
     * @title_de Aufgezeichnetes Etikett
     * @description_de Label für die Aufnahme mit aufgezeichneter Aufnahme
     */
    lblTabPrerecorded: LocalizedString
    /**
     * @title Prerecorded Tab Description
     * @description Label for Prerecorded Tab Description
     * @title_de Prerecorded Tab Description
     * @description_de Label for Prerecorded Tab Description
     */
    lblPrerecordedDescription: LocalizedString
    /**
     * @title Speakers Tab Description
     * @description Label for Speakers Tab Description
     * @title_de Speakers Tab Description
     * @description_de Label for Speakers Tab Description
     */
    lblSpeakersDescription: LocalizedString
    /**
     * @title Multimedia Tab Description
     * @description Label for Multimedia Tab Description
     * @title_de Multimedia Tab Description
     * @description_de Label for Multimedia Tab Description
     */
    lblMultimediaDescription: LocalizedString
    /**
     * @title Prerecorded video title label
     * @description Placeholder for the Prerecorded video Title input
     * @title_de Prerecorded video title label
     * @description_de Placeholder for the Prerecorded video Title input
     */
    hntDropzoneTitlePrerecorded: LocalizedString
    /**
     * @title Prerecorded video Properties placeholder
     * @description Placeholder for the Prerecorded video Properties input
     * @title_de Prerecorded video Properties placeholder
     * @description_de Placeholder for the Prerecorded video Properties input
     */
    hntDropzonePropPrerecorded: LocalizedString
    /**
     * @title Multimedia files limit
     * @description Maximum number of files one can upload in the Multimedia tab
     * @title_de --
     * @description_de --
     */
    multimediaLimit: number
    /**
     * @title Person functions Whitelist
     * @description List of person function ids for contact persons
     * @title_de Kategorie Whitelist
     * @description_de Liste der Personenfunktions-IDs für für Ansprechpartner
     */
    personFunctionWhitelist: string[]
}

export interface ShowroomsPageConfiguration {
    /**
     * @title Page Title public shorooms
     * @description Label for Page Title public shorooms
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitlePublic: LocalizedString
    /**
     * @title Page Description public shorooms
     * @description Label for Page Description public shorooms
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescriptionPublic: LocalizedString
    /**
     * @title Page Title private shorooms
     * @description Label for Page Title private shorooms
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitlePrivate: LocalizedString
    /**
     * @title Page Description private shorooms
     * @description Label for Page Description private shorooms
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescriptionPrivate: LocalizedString
    /**
     * @title Add showrooms Button
     * @description Label for the Button Add showrooms
     * @title_de --
     * @description_de --
     */
    btnAddShowrooms: LocalizedString
    /**
     * @title Add event date Section config
     * @description Label for the Add event date profile page entry
     * @title_de Ereignisdatum hinzufügen Abschnittskonfiguration
     * @description_de Label für den Profilseiteneintrag Ereignisdatum hinzufügen
     */
    sctShowrooms: ShowroomsSectionConfiguration
}

export interface ShowroomsSectionConfiguration {
    /**
     * @title Empty Showrooms title
     * @description Label for the empty Showrooms title
     * @title_de --
     * @description_de --
     */
    titleEmptyShowrooms: LocalizedString
    /**
     * @title Showrooms image label
     * @description Label for the Showrooms image
     * @title_de Bildlabel für Ereignisdatum
     * @description_de Label für das Bild des Veranstaltungsdatums
     */
    lblShowroomsImage: LocalizedString
    /**
     * @title Showrooms image message label
     * @description Label for the Showrooms image message label input
     * @title_de Etikett der Bildnachricht des Ereignisdatums
     * @description_de Label for the Event date image message label input
     */
    lblShowroomsImageMessage: LocalizedString
    /**
     * @title Showrooms Title label
     * @description Label for the Showrooms Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntTitle: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Label for the Company Image Properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntProp: LocalizedString
    /**
     * @title  Browse files Button
     * @description Label for the Button Browse files
     * @title_de Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFiles: LocalizedString
    /**
     * @title  Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImg: LocalizedString
    /**
     * @title Showrooms title label
     * @description Label for the Showrooms title label input
     * @title_de Titelbezeichnung des Veranstaltungsdatums
     * @description_de Label für die Eingabe des Titellabels für das Ereignisdatum
     */
    lblShowroomstitle: LocalizedString
    /**
     * @title  Showrooms title placeholder
     * @description Placeholder for the  Showrooms title input
     * @title_de Platzhalter für den Titel des Veranstaltungsdatums
     * @description_de Platzhalter für die Eingabe des Ereignisdatumstitels
     */
    hntShowroomstitle: LocalizedString
    /**
     * @title Description  label
     * @description Label for the Description  label input
     * @title_de Beschreibungsetikett
     * @description_de Beschriftung für die Beschriftungseingabe Beschreibung
     */
    lblDescription: LocalizedString
    /**
     * @title Description placeholder
     * @description Placeholder for the Description input
     * @title_de Beschreibung Platzhalter
     * @description_de Platzhalter für die Eingabe Beschreibung
     */
    hntDescription: LocalizedString
    /**
     * @title Access Method label
     * @description Label for the Access Method label input
     * @title_de Untertitel-Label
     * @description_de Label für die Untertitel-Label-Eingabe
     */
    lblAccessMethod: LocalizedString
    /**
     * @title Access Method placeholder
     * @description Placeholder for the Access Method input
     * @title_de Beschreibung Platzhalter
     * @description_de Platzhalter für die Eingabe Beschreibung
     */
    hntAccessMethod: LocalizedString
    /**
     * @title Option Access Method1 label
     * @description Label for the Option Access Method input
     * @title_de Lautsprecher Label
     * @description_de Bezeichnung für die Eingabe Lautsprecher
     */
    lblOptionAccessMethod1: LocalizedString
    /**
     * @title Option Access Method2 label
     * @description Label for the Option Access Method2 input
     * @title_de Multimedia-Label
     * @description_de Beschriftung für den Multimedia-Eingang
     */
    lblOptionAccessMethod2: LocalizedString
    /**
     * @title Mock showroom title (English)
     * @description Title for mock showroom item in English
     * @title_de Schein-Showroom-Titel
     * @description_de Titel für Schein-Showroom-Artikel in Englisch
     */
    titleMockShowroomEn: string
    /**
     * @title Mock showroom title (German)
     * @description Title for mock showroom item (German)
     * @title_de Schein-Showroom-Titel (Deutsch)
     * @description_de Titel für Schein-Showroom-Artikel in Deutsch
     */
    titleMockShowroomDe: string
}

export interface CouponsPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Add Coupons Button
     * @description Label for the Button Add Coupons
     * @title_de --
     * @description_de --
     */
    btnAddCoupons: LocalizedString
    /**
     * @title Coupons edit section config
     * @description Config for the section where the Coupon's fields are edited
     * @title_de Coupons edit section config
     * @description_de Config for the section where the Coupon's fields are edited
     */
    sctCoupons: CouponsSectionConfiguration
}

export interface CouponsSectionConfiguration {
    /**
     * @title Empty Coupons name
     * @description Label for the empty Coupons name
     * @title_de --
     * @description_de --
     */
    nameEmptyCoupons: LocalizedString
    /**
     * @title Coupons image label
     * @description Label for the Coupons image
     * @title_de Coupons image label
     * @description_de Label for the Coupons image
     */
    lblCouponsImage: LocalizedString
    /**
     * @title Coupons image message label
     * @description Label for the Coupons image message label input
     * @title_de Coupons image message label
     * @description_de Label for the Coupons image message label input
     */
    lblCouponsImageMessage: LocalizedString
    /**
     * @title Coupons image placeholder Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntPlaceholderTitle: LocalizedString
    /**
     * @title Coupons image placeholder Properties label
     * @description Label for the Company image placeholder Properties
     * @title_de Coupons image placeholder Properties label
     * @description_de Label for the Company image placeholder Properties
     */
    hntPlaceholderProp: LocalizedString
    /**
     * @title Browse files Button
     * @description Label for the Button Browse files
     * @title_de Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFiles: LocalizedString
    /**
     * @title Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImg: LocalizedString
    /**
     * @title Coupons name label
     * @description Label for the Coupons name label input
     * @title_de Coupons name label
     * @description_de Label for the Coupons name label input
     */
    lblCouponsName: LocalizedString
    /**
     * @title Coupons name placeholder
     * @description Placeholder for the Coupons name input
     * @title_de Coupons name placeholder
     * @description_de Placeholder for the Coupons name input
     */
    hntCouponsName: LocalizedString
    /**
     * @title URL label
     * @description Label for the URL label input
     * @title_de Untertitel-Label
     * @description_de Label für die Untertitel-Label-Eingabe
     */
    lblURL: LocalizedString
    /**
     * @title URL placeholder
     * @description Label for the URL placeholder input
     * @title_de Lautsprecher Label
     * @description_de Bezeichnung für die Eingabe Lautsprecher
     */
    hntURL: LocalizedString
    /**
     * @title Start date label
     * @description Label for the Start date input
     * @title_de Multimedia-Label
     * @description_de Beschriftung für den Multimedia-Eingang
     */
    lblCouponsValidStart: LocalizedString
    /**
     * @title Start date placeholder
     * @description Placeholder for the Start date
     * @title_de Lautsprecher Label
     * @description_de Bezeichnung für die Eingabe Lautsprecher
     */
    hntCouponsValidStart: LocalizedString
    /**
     * @title End date label
     * @description Label for the End date input
     * @title_de Multimedia-Label
     * @description_de Beschriftung für den Multimedia-Eingang
     */
    lblCouponsValidEnd: LocalizedString
    /**
     * @title End date plaecholder
     * @description Placeholder for the End date
     * @title_de Lautsprecher Label
     * @description_de Bezeichnung für die Eingabe Lautsprecher
     */
    hntCouponsValidEnd: LocalizedString
    /**
     * @title Description label
     * @description Label for the Description label input
     * @title_de Beschreibungsetikett
     * @description_de Beschriftung für die Beschriftungseingabe Beschreibung
     */
    lblDescriptionTitle: LocalizedString
    /**
     * @title Description title placeholder
     * @description Placeholder for the Description title input
     * @title_de Description title placeholder
     * @description_de Placeholder for the Description title input
     */
    hntDescriptionTitle: LocalizedString
    /**
     * @title Description long label
     * @description Label for the Description long input
     * @title_de Description long label
     * @description_de Label for the Description long input
     */
    lblDescriptionLong: LocalizedString
    /**
     * @title Description long placeholder
     * @description Placeholder for the Description long input
     * @title_de Description long placeholder
     * @description_de Placeholder for the Description long input
     */
    hntDescriptionLong: LocalizedString
    /**
     * @title Description short label
     * @description Label for the Description short label input
     * @title_de Description long label
     * @description_de Label for the Description short label input
     */
    lblDescriptionShort: LocalizedString
    /**
     * @title Description short placeholder
     * @description Placeholder for the Description short input
     * @title_de Description short placeholder
     * @description_de Placeholder for the Description short input
     */
    hntDescriptionShort: LocalizedString
    /**
     * @title Coupons start date
     * @description Coupons valid start date
     * @title_de Startdatum der Coupons
     * @description_de Gültiges Startdatum des Coupons
     */
    dateCouponsValidStart: String
    /**
     * @title Coupons end date
     * @description Coupons valid end date
     * @title_de Enddatum der Coupons
     * @description_de Gültigkeitsende der Coupons
     */
    dateCouponsValidEnd: String
}

export interface JobsPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title Add jobs Button
     * @description Label for the Button Add jobs
     * @title_de --
     * @description_de --
     */
    btnAddJobs: LocalizedString
    /**
     * @title Jobs Section config
     * @description Label for the Jobs section profile page entry
     * @title_de Ereignisdatum hinzufügen Abschnittskonfiguration
     * @description_de Label für den Profilseiteneintrag Ereignisdatum hinzufügen
     */
    sctJobs: JobsSectionConfiguration
}

export interface JobsSectionConfiguration {
    /**
     * @title Label Jobs title
     * @description Label for the  Jobs
     * @title_de --
     * @description_de --
     */
    labelJobs: LocalizedString
    /**
     * @title Empty Jobs title
     * @description Label for the empty Jobs title
     * @title_de --
     * @description_de --
     */
    titleEmptyJobs: LocalizedString
    /**
     * @title Jobs Title label
     * @description Label for the Jobs Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntTitleJob: LocalizedString
    /**
     * @title Jobs image label
     * @description Label for the Jobs image
     * @title_de --
     * @description_de --
     */
    lblJobsImage: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblJobsImageMessage: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntTitle: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Label for the Company Image Properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntProp: LocalizedString
    /**
     * @title  Browse files Button
     * @description Label for the Button Browse files
     * @title_de Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFiles: LocalizedString
    /**
     * @title  Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImg: LocalizedString
    /**
     * @title Start displaying on title label
     * @description Label for the Start displaying on title label input
     * @title_de Titelbezeichnung des Veranstaltungsdatums
     * @description_de Label für die Eingabe des Titellabels für das Ereignisdatum
     */
    lblStartDisplayinOnDate: LocalizedString
    /**
     * @title  Start displaying on title placeholder
     * @description Placeholder for the  Start displaying on title input
     * @title_de Platzhalter für den Titel des Veranstaltungsdatums
     * @description_de Platzhalter für die Eingabe des Ereignisdatumstitels
     */
    hntStartDisplayinOnDate: LocalizedString
    /**
     * @title End displaying on title label
     * @description Label for theEnd displaying on title label input
     * @title_de Titelbezeichnung des Veranstaltungsdatums
     * @description_de Label für die Eingabe des Titellabels für das Ereignisdatum
     */
    lblEndDisplayinOnDate: LocalizedString
    /**
     * @title  End displaying on title placeholder
     * @description Placeholder for the  End displaying on title input
     * @title_de Platzhalter für den Titel des Veranstaltungsdatums
     * @description_de Platzhalter für die Eingabe des Ereignisdatumstitels
     */
    hntEndDisplayinOnDate: LocalizedString
    /**
     * @title Last updated title label
     * @description Label for the Last updated title label input
     * @title_de Titelbezeichnung des Veranstaltungsdatums
     * @description_de Label für die Eingabe des Titellabels für das Ereignisdatum
     */
    lblLastUpdatedDate: LocalizedString
    /**
     * @title  Last updated title placeholder
     * @description Placeholder for the Last updated title input
     * @title_de Platzhalter für den Titel des Veranstaltungsdatums
     * @description_de Platzhalter für die Eingabe des Ereignisdatumstitels
     */
    hntLastUpdatedDate: LocalizedString
    /**
     * @title Job start title label
     * @description Label for the Job start title label input
     * @title_de Titelbezeichnung des Veranstaltungsdatums
     * @description_de Label für die Eingabe des Titellabels für das Ereignisdatum
     */
    lblJobStartDate: LocalizedString
    /**
     * @title  Job start title placeholder
     * @description Placeholder for the Job start title input
     * @title_de Platzhalter für den Titel des Veranstaltungsdatums
     * @description_de Platzhalter für die Eingabe des Ereignisdatumstitels
     */
    hntJobStartDate: LocalizedString
    /**
     * @title Job location title label
     * @description Label for the Job location title label input
     * @title_de Titelbezeichnung des Veranstaltungsdatums
     * @description_de Label für die Eingabe des Titellabels für das Ereignisdatum
     */
    lblJobLocation: LocalizedString
    /**
     * @title  Job location title placeholder
     * @description Placeholder for the Job location title input
     * @title_de Platzhalter für den Titel des Veranstaltungsdatums
     * @description_de Platzhalter für die Eingabe des Ereignisdatumstitels
     */
    hntJobLocation: LocalizedString
    /**
     * @title Employment type title label
     * @description Label for the Employment type title label input
     * @title_de Titelbezeichnung des Veranstaltungsdatums
     * @description_de Label für die Eingabe des Titellabels für das Ereignisdatum
     */
    lblEmploymentType: LocalizedString
    /**
     * @title  Employment type title placeholder
     * @description Placeholder for the Employment type title input
     * @title_de Platzhalter für den Titel des Veranstaltungsdatums
     * @description_de Platzhalter für die Eingabe des Ereignisdatumstitels
     */
    hntEmploymentType: LocalizedString
    /**
     * @title Employment Type
     * @description Type of employment available
     * @title_de Art der Beschäftigung
     * @description_de Art der verfügbaren Beschäftigung
     */
    employmentType: ValidationFieldNames[]
    /**
     * @title Employment level title label
     * @description Label for the Employment level title label input
     * @title_de Titelbezeichnung des Veranstaltungsdatums
     * @description_de Label für die Eingabe des Titellabels für das Ereignisdatum
     */
    lblEmploymentLevel: LocalizedString
    /**
     * @title  Employment level title placeholder
     * @description Placeholder for the Employment level title input
     * @title_de Platzhalter für den Titel des Veranstaltungsdatums
     * @description_de Platzhalter für die Eingabe des Ereignisdatumstitels
     */
    hntEmploymentLevel: LocalizedString
    /**
     * @title Career Level
     * @description Career Level available
     * @title_de Karrierestufe
     * @description_de Verfügbare Karrierestufen
     */
    careerLevel: ValidationFieldNames[]
    /**
     * @title Salary group title label
     * @description Label for the Salary group title label input
     * @title_de Titelbezeichnung der Gehaltsgruppe
     * @description_de Beschriftung für die Eingabe des Titels der Gehaltsgruppe
     */
    lblSalaryGroup: LocalizedString
    /**
     * @title Salary group title placeholder
     * @description Placeholder for the Salary group title input
     * @title_de Platzhalter für Gruppentitel
     * @description_de Platzhalter für die Eingabe des Titels der Gehaltsgruppe
     */
    hntSalaryGroup: LocalizedString
    /**
     * @title Salary Group
     * @description Salary Group available
     * @title_de Gehaltsgruppe
     * @description_de Gehaltsgruppe verfügbar
     */
    salaryGroup: ValidationFieldNames[]
    /**
     * @title Description  label
     * @description Label for the Description  label input
     * @title_de Beschreibungsetikett
     * @description_de Beschriftung für die Beschriftungseingabe Beschreibung
     */
    lblJobDescription: LocalizedString
    /**
     * @title Description placeholder
     * @description Placeholder for the Description input
     * @title_de Beschreibung Platzhalter
     * @description_de Platzhalter für die Eingabe Beschreibung
     */
    hntJobDescription: LocalizedString
    /**
     * @title Job requirements label
     * @description Label for the Job requirements label input
     * @title_de Untertitel-Label
     * @description_de Label für die Untertitel-Label-Eingabe
     */
    lblJobReqirements: LocalizedString
    /**
     * @title Job requirements placeholder
     * @description Label for the  Job requirements placeholder input
     * @title_de Lautsprecher Label
     * @description_de Bezeichnung für die Eingabe Lautsprecher
     */
    hntJobReqirements: LocalizedString
    /**
     * @title Categories label
     * @description Label for the Categories input
     * @title_de Kategorien Label
     * @description_de Bezeichnung für die Eingabe Kategorien
     */
    lblTabCategories: LocalizedString
    /**
     * @title Tab Contacts label
     * @description Label for the Tab Contacts label input
     * @title_de Untertitel-Label
     * @description_de Label für die Untertitel-Label-Eingabe
     */
    lblTabContacts: LocalizedString
    /**
     * @title Tab Multimedia label
     * @description Label for the Tab Multimedia label input
     * @title_de Untertitel-Label
     * @description_de Label für die Untertitel-Label-Eingabe
     */
    lblTabMultimedia: LocalizedString
    /**
     * @title Categories Tab Description
     * @description Label for Categories Tab Description
     * @title_de Categories Tab Description
     * @description_de Label for Categories Tab Description
     */
    lblCategoriesDescription: LocalizedString
    /**
     * @title Contacts Tab Description
     * @description Label for Contacts Tab Description
     * @title_de Contacts Tab Description
     * @description_de Label for Contacts Tab Description
     */
    lblContactsDescription: LocalizedString
    /**
     * @title Multimedia Tab Description
     * @description Label for Multimedia Tab Description
     * @title_de Multimedia Tab Description
     * @description_de Label for Multimedia Tab Description
     */
    lblMultimediaDescription: LocalizedString
    /**
     * @title Homeoffice label
     * @description Label for Homeoffice radio button group
     * @title_de --
     * @description_de --
     */
    lblHomeoffice: LocalizedString
    /**
     * @title Homeoffice label NA
     * @description Label for Homeoffice radio button - option NA
     * @title_de --
     * @description_de --
     */
    lblHomeofficeNA: LocalizedString
    /**
     * @title Homeoffice label NO
     * @description Label for Homeoffice radio button - option NO
     * @title_de --
     * @description_de --
     */
    lblHomeofficeNO: LocalizedString
    /**
     * @title Homeoffice label YES
     * @description Label for Homeoffice radio button - option YES
     * @title_de --
     * @description_de --
     */
    lblHomeofficeYES: LocalizedString
    /**
     * @title Multimedia files limit
     * @description Maximum number of files one can upload in the Multimedia tab
     * @title_de --
     * @description_de --
     */
    multimediaLimit: number
    /**
     * @title Person functions Whitelist
     * @description List of person function ids for contact persons
     * @title_de Kategorie Whitelist
     * @description_de Liste der Personenfunktions-IDs für für Ansprechpartner
     */
    personFunctionWhitelist: string[]
    /**
     * @title Category Whitelist
     * @description List of category ids for root elements
     * @title_de Kategorie Whitelist
     * @description_de Liste von Kategorie IDs für Rootelemenete
     */
    categoryWhitelist: string[]
    /**
     * @title Job background image label
     * @description Label for Job background image
     * @title_de --
     * @description_de --
     */
    lblJobBackgroundImage: LocalizedString
    /**
     * @title  Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteJobBackgroundImg: LocalizedString
    /**
     * @title Jobs background image message label
     * @description Label for the Jobs background image message label input
     * @title_de --
     * @description_de --
     */
    lblJobsBackgroundImgMessage: LocalizedString
}

export interface PrintPageConfiguration {
    /**
     * @title Deadline
     * @description Sets the deadline when the print will be disabled.
     * @title_de Deadline
     * @description_de Setzt die Deadline ab dem der Print deaktiviert wird.
     */
    deadLine: string
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de Seitentitel
     * @description_de Beschriftung für Seitentitel
     */
    pageTitle: LocalizedString
    /**
     * @title Page Description
     * @description Label for Page Description
     * @title_de Seitenbeschreibung
     * @description_de Beschriftung für Seitenbeschreibung
     */
    pageDescription: LocalizedString
    /**
     * @title PrintDeadline label
     * @description Label for PrintDeadline field
     * @title_de --
     * @description_de --
     */
    printDeadline: LocalizedString
    /**
     * @title Copy data label
     * @description Label for the Copy data toggle
     * @title_de --
     * @description_de --
     */
    lblCopyData: LocalizedString
    /**
     * @title Background logo config
     * @description Config for the logo section
     * @title_de --
     * @description_de --
     */
    sctLogoBackgroundImage: LogoBackgroundImageSectionConfiguration
    /**
     * @title Name Section config
     * @description Configuration for the name section
     * @title_de --
     * @description_de --
     */
    sctCompanyName: CompanyNameSectionConfiguration
    /**
     * @title Sort Section config
     * @description Configuration for the sort section
     * @title_de --
     * @description_de --
     */
    sctCompanySort: CompanySortSectionConfiguration
    /**
     * @title Address Section config
     * @description Configuration for the Address section print page entry
     * @title_de --
     * @description_de --
     */
    sctAddressPrintCatalogue: AddressPrintCatalogueSectionConfiguration
    /**
     * @title Contact Section config
     * @description Configuration for the Contact section
     * @title_de --
     * @description_de --
     */
    sctContactInformation: ContactInformationSectionConfiguration
    /**
     * @title Description Section config
     * @description Configuration for the Description section
     * @title_de --
     * @description_de --
     */
    sctCompanyDescription: CompanyDescriptionSectionConfiguration
    /**
     * @title Ad Section config
     * @description Configuration for the Ad section
     * @title_de --
     * @description_de --
     */
    sctAds: AdsSectionConfiguration
}

export interface LogoBackgroundImageSectionConfiguration {
    /**
     * @title Jobs image label
     * @description Label for the Jobs image
     * @title_de --
     * @description_de --
     */
    lblSectionTitleLogoBackground: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblCompanyLogo: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblCompanyLogoImageMessage: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntTitle: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Label for the Company Image Properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntProp: LocalizedString
    /**
     * @title  Browse files Button
     * @description Label for the Button Browse files
     * @title_de Schaltfläche "Dateien durchsuchen"
     * @description_de Bezeichnung für die Schaltfläche Dateien durchsuchen
     */
    btnBrowseFiles: LocalizedString
    /**
     * @title  Delete current image Button
     * @description Label for the Button Delete current image
     * @title_de Aktuelles Bild löschen Button
     * @description_de Beschriftung für die Schaltfläche Aktuelles Bild löschen
     */
    btnDeleteImg: LocalizedString
}

export interface CompanyNameSectionConfiguration {
    /**
     * @title Company name section label
     * @description Label for the Company name section
     * @title_de --
     * @description_de --
     */
    lblSectionCompanyName: LocalizedString
    /**
     * @title Company name label
     * @description Label for the Company name label input
     * @title_de --
     * @description_de --
     */
    lblCompanyName: LocalizedString
    /**
     * @title Company name label
     * @description Label for the Company name input
     * @title_de --
     * @description_de --
     */
    hntCompanyName: LocalizedString
}

export interface CompanySortSectionConfiguration {
    /**
     * @title Company sort section label
     * @description Label for the Company sort section
     * @title_de --
     * @description_de --
     */
    lblSectionCompanySort: LocalizedString
    /**
     * @title Company sort label
     * @description Label for the Compay sort label input
     * @title_de --
     * @description_de --
     */
    lblCompanySort: LocalizedString
}

export interface AddressPrintCatalogueSectionConfiguration {
    /**
     * @title Jobs image label
     * @description Label for the Jobs image
     * @title_de --
     * @description_de --
     */
    lblSectionAddressInformation: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblAddress1: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntAddress1: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblAddress2: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntAddress2: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblAddress3: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntAddress3: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblZipCode: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntZipCode: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblCity: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntCity: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblCountry: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntCountry: LocalizedString
}

export interface ContactInformationSectionConfiguration {
    /**
     * @title Jobs image label
     * @description Label for the Jobs image
     * @title_de --
     * @description_de --
     */
    lblSectionContactInfo: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblPhone: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntPhone: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblMobile: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntMobile: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblFax: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntFax: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblEmail: LocalizedString
    /**
     * @title Coupons Title label
     * @description Label for the Coupons Title input
     * @title_de Titelbezeichnung des Nachrichten
     * @description_de Beschriftung für die Eingabe des Nachrichten
     */
    hntEmail: LocalizedString
    /**
     * @title Jobs image message label
     * @description Label for the Jobs image message label input
     * @title_de --
     * @description_de --
     */
    lblWebsite: LocalizedString
    /**
     * @title Website hint
     * @description Hint for the Website input
     * @title_de --
     * @description_de --
     */
    hntWebsite: LocalizedString
}

export interface CompanyDescriptionSectionConfiguration {
    /**
     * @title Print description label
     * @description Label for the Description section
     * @title_de --
     * @description_de --
     */
    lblSectionCompanyDescription: LocalizedString
    /**
     * @title Company Portrait label
     * @description Label for the Company Portrait text area
     * @title_de --
     * @description_de --
     */
    lblCompanyPortrait: LocalizedString
    /**
     * @title Company Portrait hint
     * @description Hint for the searchbar input field
     * @title_de --
     * @description_de --
     */
    hntCompanyPortrait: LocalizedString
}
export interface AdsSectionConfiguration {
    /**
     * @title Ads Section label
     * @description Label for the Ads Section
     * @title_de --
     * @description_de --
     */
    lblAdsSection: LocalizedString
    /**
     * @title Ads Section description label
     * @description Label for the description of the Ads Section
     * @title_de --
     * @description_de --
     */
    lblAdsDecription: LocalizedString
    /**
     * @title Company Image Title label
     * @description Placeholder for the Company Image Title input
     * @title_de Titelbezeichnung des Firmenbilds
     * @description_de Beschriftung für die Eingabe des Unternehmensbildtitels
     */
    hntDropzoneTitle: LocalizedString
    /**
     * @title Company Image Properties placeholder
     * @description Placeholder for the Company Image Properties input
     * @title_de Eigenschaften des Unternehmensimages
     * @description_de Bezeichnung für die Eingabe "Unternehmensbildeigenschaften"
     */
    hntDropzoneProp: LocalizedString
    /**
     * @title Position label
     * @description Label for the Position label input
     * @title_de Positionsbezeichnung
     * @description_de Label für die Positionslabel-Eingabe
     */
    lblHeaderPosition: LocalizedString
    /**
     * @title Preview label
     * @description Label for the Preview label input
     * @title_de Etikettenvorschau
     * @description_de Label für die Label-Eingabe in der Vorschau
     */
    lblHeaderPreview: LocalizedString
    /**
     * @title File label
     * @description Label for the File label input
     * @title_de Dateibezeichnung
     * @description_de Label für die Dateilabel-Eingabe
     */
    lblHeaderFile: LocalizedString
}

export interface EventShopPageConfiguration {
    /**
     * @title Page Title
     * @description Label for Page Title
     * @title_de --
     * @description_de --
     */
    eventShopUrl: LocalizedString
}

export interface ValidationFieldNames {
    /**
     * @title Validated field's name
     * @description Field name as key for validated fields
     * @title_de --
     * @description_de --
     */
    name: string
    /**
     * @title Validated field's label
     * @description Label to be shown for the validated field
     * @title_de --
     * @description_de --
     */
    value: LocalizedString
}

export interface ValidationSectionConfiguration {
    /**
     * @title Section's label
     * @description Label for the validated section with erroneous fields
     * @title_de --
     * @description_de --
     */
    name: LocalizedString
    /**
     * @title Section's label
     * @description Label for the validated section with erroneous fields
     * @title_de --
     * @description_de --
     */
    nameIndex: LocalizedString
    /**
     * @title Field mames's labels
     * @description Array of labels for validated fields
     * @title_de --
     * @description_de --
     */
    fieldNames: ValidationFieldNames[]
}

export interface ValidationFieldNamesConfiguration {
    /**
     * @title Organization's validated fields' names
     * @description Validation configuration for the organization section
     * @title_de --
     * @description_de --
     */
    organization: ValidationSectionConfiguration
    /**
     * @title Links validated fields' names
     * @description Validation configuration for the links section
     * @title_de --
     * @description_de --
     */
    links: ValidationSectionConfiguration
    /**
     * @title Products validated fields' names
     * @description Validation configuration for the products section
     * @title_de --
     * @description_de --
     */
    products: ValidationSectionConfiguration
    /**
     * @title Trademarks validated fields' names
     * @description Validation configuration for the trademarks section
     * @title_de --
     * @description_de --
     */
    trademarks: ValidationSectionConfiguration
    /**
     * @title Persons validated fields' names
     * @description Validation configuration for the persons section
     * @title_de --
     * @description_de --
     */
    persons: ValidationSectionConfiguration
    /**
     * @title MEdias validated fields' names
     * @description Validation configuration for the medias section
     * @title_de --
     * @description_de --
     */
    medias: ValidationSectionConfiguration
    /**
     * @title News validated fields' names
     * @description Validation configuration for the news section
     * @title_de --
     * @description_de --
     */
    newsList: ValidationSectionConfiguration
    /**
     * @title Event dates validated fields' names
     * @description Validation configuration for the event dates section
     * @title_de --
     * @description_de --
     */
    eventDates: ValidationSectionConfiguration
    /**
     * @title Showrooms validated fields' names
     * @description Validation configuration for the showrooms section
     * @title_de --
     * @description_de --
     */
    showrooms: ValidationSectionConfiguration
    /**
     * @title Coupons validated fields' names
     * @description Validation configuration for the coupons section
     * @title_de --
     * @description_de --
     */
    goodies: ValidationSectionConfiguration
    /**
     * @title Jobs validated fields' names
     * @description Validation configuration for the jobs section
     * @title_de --
     * @description_de --
     */
    jobOffers: ValidationSectionConfiguration
    /**
     * @title PrintCatalog validated fields' names
     * @description Validation configuration for the print catalog section
     * @title_de --
     * @description_de --
     */
    printCatalog: ValidationSectionConfiguration
}

export type LocalizedString = string & { __localizedBrand?: undefined }
export type ColorString = string & { __colorBrand?: undefined }

declare let branding: Branding
branding = new BrandingImpl(branding, "en")
const brandingImplCallable = branding as any

function getBranding(): Branding {
    return branding
}
export default getBranding()

export function setBrandingLanguage(lang: string) {
    brandingImplCallable.setLang(lang)
}
